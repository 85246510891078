import React from 'react';
import { useTranslation } from 'react-i18next';

//  Other
import {MODAL_TYPES} from '../data/constants';

const Modal = (props) => {
    const { t } = useTranslation();

    const visible = props.modalType !== MODAL_TYPES.HIDDEN;
    const className = 'modal-overlay ' + (visible ? 'visible' : '');

    const headerText = props.modalType === MODAL_TYPES.START_OVER ? t('intro.areYouSure') : t('compatibilityCheck.labelConfirmPurchase');
    const bodyText = props.modalType === MODAL_TYPES.START_OVER ? t('intro.startingOver') : t('compatibilityCheck.confirmPurchase');
    const btn0Text = props.modalType === MODAL_TYPES.START_OVER ? t('intro.startOver') : t('wires.yes');
    const btn1Text = props.modalType === MODAL_TYPES.START_OVER ? t('intro.cancel') : t('wires.no');

    return (
        <div className={className}>
            <div className="popup">
                <header>
                    <p className="menu-heading">{headerText}</p>
                    <button
                        className="close-button dark"
                        onClick={() => props.closeModal()}
                    ></button>
                </header>
                <main>
                    <p className="help-text">{bodyText}</p>
                    <div className="button-container">
                        <button
                            className="btn btn-continue"
                            onClick={props.modalActionYes}
                        >
                            {btn0Text}
                        </button>
                        <button
                            className="btn btn-start-over"
                            onClick={props.modalActionNo}
                        >
                            {btn1Text}
                        </button>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Modal;
