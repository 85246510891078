import React, { useEffect, useState } from 'react';

//  Components
import Checklist from './ChecklistDesktop';
import SectionHeader from './SectionHeader';
import ExpandedInfo from './ExpandedInfo';
import Step from './StepHeader';
import StepCollection from './StepCollection';

//  Other
import { exists } from '../utilities/statics';
import ConfigurationManager from '../services/configuration';
import { SECTION_TYPES } from '../data/constants';

//  Local constants
const TOOL_LIST = 'TOOL_LIST';
const TOOL_INDEX = -1;

const SectionScreen = (props) => {

    const { mustHaveTools, mayNeedTools, steps } = props.section;

    /* ****************************************************************
     *      State hooks
    **************************************************************** */

    const [expandedStep, setExpandedStep] = useState(0); // The data we currently display
    const [expandedIndex, setExpandedIndex] = useState(null); // The index for that data, into the section.steps array

    /* ****************************************************************
     *      Branching (must be defined before the effect hooks)
    **************************************************************** */

    const visibleSteps = ConfigurationManager.getSectionType(steps) === SECTION_TYPES.RESULTS
        ? ConfigurationManager.getResults()
        : steps;


    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  Whenever we show a new section, show the first step (typically the tool list)
    useEffect(() => {
        showFirstStep();

        //  TODO: this generates a warning, but I'm not satisfied with any of the answers in
        //  TODO: https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
        //  TODO: I'm disabling the warning for now.
    }, [props.sectionIndex]);  // eslint-disable-line react-hooks/exhaustive-deps

    /* ****************************************************************
     *      Methods
    **************************************************************** */

    const showFirstStep = () => {
        if (hasTools()) {
            //  Show the tool list.
            launchInfo(TOOL_LIST, TOOL_INDEX);
        } else {
            //  There aren't any tools. Show the first step.
            launchInfo(visibleSteps[0], 0);
        }
    }

    const hasTools = () => {
        return (mustHaveTools && mustHaveTools.length > 0) || (mayNeedTools && mayNeedTools.length > 0);
    }

    const launchInfo = (step, index) => {
        setExpandedStep(step);
        // console.log('launchInfo: seeting expanded index to ', index, '; seeting step to ', step.branchName)
        setExpandedIndex(index);
    }

    const atLastStep = () => {
        if (!exists(expandedIndex) || expandedIndex === TOOL_INDEX) {
            return false;
        }
        // console.log('expandedIndex: ', expandedIndex);
        return expandedIndex === visibleSteps.length - 1;
    }

    const nextStep = () => {
        if (!atLastStep()) {
            const nextIndex = expandedIndex + 1;
            const nextStep = visibleSteps[nextIndex];
            launchInfo(nextStep, nextIndex);
        }
    }

    const nextStepAvailable = () => {
        return true;
    }

    const nextStepFunction = atLastStep()
        ? props.atLastSection
            ? null
            : props.selectNextSection
        : nextStepAvailable()
            ? nextStep
            : null;


    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    const toolsStep = (<Step
        key={TOOL_LIST}
        index={0}
        step={{labelKey: 'intro.itemsNeeded', checked: null}}
        launchInfo={showFirstStep}
        toggleCheck={() => {}} // no-op
        focus={expandedStep === TOOL_LIST}
        isNumbered={false}
    />);

    // For the Checklist, I tried using expandedIndex to conditionally compile
    // checklist vs. expandedInfo, but it led to a bug
    // with the embedded images. Using the `visible` property,
    // instead of conditional compilation, fixed this issue.

    return (
        <div className="section-container">
            <SectionHeader {...props} />
            <main>
                <div className="column-left">
                    <StepCollection
                        steps={visibleSteps}
                        isMobile={false}
                        expandedStep={expandedStep}
                        launchInfo={launchInfo}
                        toggleCheck={props.toggleCheck}
                        toolsStep={hasTools() ? toolsStep : null}
                    />
                </div>
                <div className="column-right">
                    <Checklist
                        mustHaveTools={mustHaveTools}
                        mayNeedTools={mayNeedTools}
                        sectionIndex={props.sectionIndex}
                        toggleCheck={props.toggleCheck}
                        nextStep={nextStep}
                        visible={expandedIndex === TOOL_INDEX}
                    />
                    <ExpandedInfo
                        step={expandedStep}
                        index={expandedIndex}
                        closeInfo={() => {}} // no-op
                        nextStep={nextStepFunction}
                        visible={expandedIndex !== TOOL_INDEX}
                        selectOption={props.selectOption}
                        canClose={false}
                    />
                </div>
            </main>
        </div>
    );
};

export default SectionScreen;
