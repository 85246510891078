import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18n from "../services/i18n";

//  Components
import TranslatedListContent from './TranslatedListContent';
import StepSelect from './StepSelect';

//  Other
import ConfigurationManager from '../services/configuration';
import {exists} from "../utilities/statics";

const ExpandedInfo = (props) => {
    const hasOptions = exists(props.step) && ConfigurationManager.hasOptions(props.step);

    //  Store refs, so we can replace images, and so we can scroll to top programmatically
    const scrollRef = useRef(null);

    //  Effect hook: If the user scrolls down and then dismisses the overlay, we don't
    //  want it to remain scrolled, when she invokes it again
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            const node = scrollRef.current;
            const transitionEndHandler = () => {
                node.removeEventListener('webkitTransitionEnd', transitionEndHandler);
                node.scrollTop = 0;
            };
            node.addEventListener('webkitTransitionEnd', transitionEndHandler);
        }
    });

    const { t } = useTranslation();

    const labelKey = props.step && props.step.labelKey ? props.step.labelKey : null;
    const labelClass = props.step && props.step.labelClassOverride ? props.step.labelClassOverride : "label";
    const contentKey = props.step && props.step.key ? props.step.key : null;

    const labelText = props.canClose
        ? t(labelKey)
        : `${props.index + 1}. ${t(labelKey)}`;

    const className = 'expanded-info ' + (props.visible ? 'visible' : '');
    const classNameNextStep = 'btn btn-next-step' + (i18n.language ? ' btn-next-step-lang-' + i18n.language : '');

    const nextStepButton = props.nextStep
        ? (<button
                className={classNameNextStep}
                onClick={props.nextStep}
            >
                <Trans i18nKey="intro.nextStep" />
            </button>)
        : null;

    const selection = hasOptions
        ? <StepSelect {...props} />
        : null;

    return (
        <div
            className={className}
            ref={scrollRef}
        >
            {props.canClose
                ? <header>
                    <button
                        className="close-button dark"
                        onClick={() => props.closeInfo()}
                    ></button>
                </header>
                : null}
            <main>
                <p className={labelClass}>
                    {labelText}
                </p>
                <TranslatedListContent
                    {...props}
                    contentClass="content"
                    contentKey={contentKey}
                />
                {selection}
                {props.canClose
                    ? null
                    : nextStepButton}

            </main>
        </div>
    );
};

export default ExpandedInfo;
