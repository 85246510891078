import React from 'react';
import { Trans } from 'react-i18next';

import ToolListItem from './ToolListItem';

const ChecklistMobile = (props) => {
    const mustHaveVisible = props.mustHaveTools && props.mustHaveTools.length > 0;
    const mayNeedVisible = props.mayNeedTools && props.mayNeedTools.length > 0;
    const className = 'checklist' + (mustHaveVisible || mayNeedVisible ? ' visible' : '');

    const createList = (tools) => {
        return tools.map(tool => <ToolListItem key={tool.key} tool={tool} toggleCheck={props.toggleCheck}/>);
    }

    const mustHaveElements = mustHaveVisible
        ? (
            <div>
                <div className="checklist-section-header">
                    <p className="label">
                        <Trans i18nKey="tools.youWillNeed" />
                    </p>
                </div>
                <ul>
                    {createList(props.mustHaveTools)}
                </ul>
            </div>)
        : null;

    const mayNeedElements = mayNeedVisible
        ? (
            <div>
                <div className="checklist-section-header">
                    <p className="label">
                        <Trans i18nKey="tools.youMayNeed" />
                    </p>
                </div>
                <ul>
                    {createList(props.mayNeedTools)}
                </ul>
            </div>)
        : null;

    return (
        <div className={className} >
            <header>
                <p className="label">
                    <Trans i18nKey="intro.toolsChecklist" />
                </p>
                <p className="in-box">
                    <Trans i18nKey="tools.inBox" />
                </p>
            </header>
            <main>
                {mustHaveElements}
                {mayNeedElements}
            </main>
        </div>
    );
};

export default ChecklistMobile;
