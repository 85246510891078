import React, {useEffect, useRef} from 'react';
import { Trans, useTranslation } from 'react-i18next';

//  Components
import ToolListItem from './ToolListItem';

//  Other
import { getScrollDiv } from '../utilities/statics';

const Tools = (props) => {

    const { t } = useTranslation();

    //  Store ref, so we can scroll to top programmatically
    const scrollRef = useRef(null);

    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  If the user scrolls the checklist down and then goes to another section,
    //  we don't want it to remain scrolled.
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            //  Since display is toggled from 'none'->'block', we need to wait for it to be displayed
            //  before we can accurately reset the scrolling. We use requestAnimationFrame().
            requestAnimationFrame(() => {
                getScrollDiv(scrollRef.current).scrollTo({ top: 0 });
            });
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    const createList = (toolList) => {
        return toolList.map(tool => <ToolListItem key={tool.key} tool={tool} toggleCheck={props.toggleCheck}/>);
    }

    const sortTools = (tools) => {
        return tools
            .sort((tool1, tool2) => {
                const key1 = t(tool1.key);
                const key2 = t(tool2.key);
                if (key1 < key2) {
                    return -1;
                }
                if (key1 > key2) {
                    return 1;
                }
                return 0;
            }).sort((tool1, tool2) => {
                if (tool1.included && !tool2.included) {
                    return -1;
                }
                if (!tool1.included && tool2.included) {
                    return 1;
                }
                return 0;
            });
    }

    const mustHaveElements = createList(sortTools(props.mustHaveTools));
    const mayNeedElements = createList(sortTools(props.mayNeedTools));

    return (
        <div
            className="tool-list"
            ref={scrollRef}
        >
            <div className="checklist visible" >
                <div className="checklist-section-header">
                    <p className="label">
                        <Trans i18nKey="tools.youWillNeed" />
                    </p>
                    <p className="in-box">
                        <Trans i18nKey="tools.inBox" />
                    </p>
                </div>
                <ul>
                    {mustHaveElements}
                </ul>
            </div>
            <div className="checklist visible" >
                <div className="checklist-section-header">
                    <p className="label">
                        <Trans i18nKey="tools.youMayNeed" />
                    </p>
                    <p className="in-box">
                        <Trans i18nKey="tools.inBox" />
                    </p>
                </div>
                <ul>
                    {mayNeedElements}
                </ul>
            </div>
        </div>
    );
}

export default Tools;
