import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './utilities/statics';
import { MEDIA_QUERIES, URL } from './data/constants';
import { BreakpointProvider } from './services/breakpoints';
import { Router } from './services/router';
import i18n
    from "./services/i18n";

//  Styles
import './styles/index.css';
import './styles/mobile.css';
import './styles/desktop.css';


ReactDOM.render(
  <React.StrictMode>
      <BreakpointProvider queries={MEDIA_QUERIES}>
          <Router routes={URL} NotFound={null}>
              <I18nextProvider i18n={i18n}>
                <App />
              </I18nextProvider>
          </Router>
      </BreakpointProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
