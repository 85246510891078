import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

//  Local
import { exists, getImageSource } from '../utilities/statics';

const StepSelect = (props) => {
    const { t } = useTranslation();
    const elements = props.step.options.map((option) => {
        let label;
        if (exists(option.labelKeys)) {
            label = option.labelKeys.map((key) => {
                const str = ` ${t(key)}`; // The initial space is deliberate; it forces wrapping
                return (<span
                    className="option-label"
                    key={key}
                >
                    {str}
                </span>);
            });
        } else {
            label = <Trans i18nKey={option.labelKey} />;
        }

        const optionImageDiv = exists(option.image)
            ? (
                <div className="img-holder">
                    <img
                        src={getImageSource(option.image)}
                        className="illustration"
                        alt="illustration"
                    />
                </div>
            )
            : null;

        return (
            <li
                key={option.type}
            >
                <div className="option-holder">
                    <p className="item-name">{label}</p>
                    <input
                        name={option.type} /* unique to this step */
                        type="checkbox"
                        checked={JSON.parse(option.checked)}
                        onChange={() => props.selectOption({branchName: props.step.branchName, type: option.type})}
                    />
                </div>
                {optionImageDiv}
            </li>);
    });

    const stepImageDiv = exists(props.step.image)
        ? (
            <div className="img-holder">
                <img
                    src={getImageSource(props.step.image)}
                    className="illustration"
                    alt="illustration"
                />
            </div>
        )
        : null;

    return (
        <ul className="select-options">
            {stepImageDiv}
            {elements}
        </ul>
    );
};

export default StepSelect;
