import React from 'react';

//  Components
import SectionScreenMobile from './SectionScreenMobile';
import SectionScreenDesktop from './SectionScreenDesktop';
import CompatibilityCheckMobile from './CompatibilityCheckMobile';
import CompatibilityCheckDesktop from './CompatibilityCheckDesktop';

//  Other services
import { isMobile } from '../services/breakpoints';

const SectionScreen = (props) => {
    const Screen = isMobile()
        ? props.isBranching ? CompatibilityCheckMobile : SectionScreenMobile
        : props.isBranching ? CompatibilityCheckDesktop : SectionScreenDesktop;

    return (
        <Screen {...props} />
    );
};

export default SectionScreen;
