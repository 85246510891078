import React, {
    useState,
    useEffect,
    createContext,
    useContext} from 'react';
import { MOBILE_PORTRAIT, MOBILE_LANDSCAPE, TABLET_LANDSCAPE } from '../data/constants';

const defaultValue = {}

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({children, queries}) => {

    const [queryMatch, setQueryMatch] = useState({});

    useEffect(() => {
        const mediaQueryLists = {};
        const keys = Object.keys(queries);
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc, media) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {})
            setQueryMatch(updatedMatches)
        }

        if (window && window.matchMedia) {
            const matches = {};
            keys.forEach(media => {
                if (typeof queries[media] === 'string') {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches
                } else {
                    matches[media] = false
                }
            });
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach(media => {
                if(typeof queries[media] === 'string') {
                    mediaQueryLists[media].addListener(handleQueryListener)
                }
            });
        }

        return () => {
            if(isAttached) {
                keys.forEach(media => {
                    if(typeof queries[media] === 'string') {
                        mediaQueryLists[media].removeListener(handleQueryListener)
                    }
                });
            }
        }
    }, [queries]);

    return (
        <BreakpointContext.Provider value={queryMatch}>
            {children}
        </BreakpointContext.Provider>
    )

}

function useBreakpoint() {
    const context = useContext(BreakpointContext);
    if(context === defaultValue) {
        throw new Error('useBreakpoint must be used within BreakpointProvider');
    }
    return context;
}

function isMobile() {
    //  We cache useBreakpoint() so that we don't put the `||` operator
    //  in front of it; this can cause the browser to think that the ref is conditional
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const breakpoint = useBreakpoint();
    return breakpoint[MOBILE_PORTRAIT] || breakpoint[MOBILE_LANDSCAPE];
}

// function isTablet() {
//     //  We cache useBreakpoint() so that we don't put the `||` operator
//     //  in front of it; this can cause the browser to think that the ref is conditional
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     const breakpoint = useBreakpoint();
//     return breakpoint[TABLET_PORTRAIT] || breakpoint[TABLET_LANDSCAPE];
// }

function isLandscape() {
    //  We cache useBreakpoint() so that we don't put the `||` operator
    //  in front of it; this can cause the browser to think that the ref is conditional
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const breakpoint = useBreakpoint();
    return breakpoint[MOBILE_LANDSCAPE] || breakpoint[TABLET_LANDSCAPE];
}

export {useBreakpoint, isMobile, isLandscape, BreakpointProvider};
