import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

//  Other
import { getScrollDiv } from '../utilities/statics';

const Overview = (props) => {
    const { t } = useTranslation();

    //  Store ref, so we can replace images, determine if we need to truncate the step,
    //  and scroll to top programmatically
    const scrollRef = useRef(null);

    //  Effect hook: If the user scrolls down on a different screen (e.g., Tools),
    //  we want to scroll back to top when she invokes this screen
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            const node = getScrollDiv(scrollRef.current);
            node.scrollTop = 0;
        }
    });

    const options = props.labels.map((label) => {
        return (<li
            key={label.labelKey}
            className={label.completed ? "checked" : ""}
        >
            <div>
                {t(label.labelKey)}
                <input
                    name="completed"
                    type="checkbox"
                    checked={label.completed}
                    onChange={() => {}} // no-op
                />
            </div>
        </li>);
    });

    const startOverClassName = `btn btn-start-over ${props.showStartOverButton ? '' : 'hidden'}`;

    return (
        <div
            className="overview"
            ref={scrollRef}
        >
            <main>
                <div className="column-left">
                    {/* desktop only -- contains an image */}
                </div>
                <div className="column-right">
                    <div className="overview-container">
                        <p className="section-title"><Trans i18nKey="menu.installationGuide" /></p>
                        <ol>
                            {options}
                        </ol>
                    </div>
                    <div className="button-container">
                        <button
                            className="btn btn-continue"
                            onClick={props.continue}
                        >
                            <Trans i18nKey="intro.continue" />
                        </button>
                        <button
                            className={startOverClassName}
                            onClick={props.startOver}
                        >
                            <Trans i18nKey="intro.startOver" />
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Overview;
