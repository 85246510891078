import React, { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import i18n from '../services/i18n';

import ToolListItem from './ToolListItem';

const ChecklistDesktop = (props) => {
    //  Store ref, so we can scroll to top programmatically
    const scrollRef = useRef(null);

    //  If the user scrolls the checklist down and then goes to another section,
    //  we don't want it to remain scrolled.
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            const node = scrollRef.current;

            //  Since display is toggled from 'none'->'block', we need to wait for it to be displayed
            //  before we can accurately reset the scrolling. We use requestAnimationFrame().
            requestAnimationFrame(() => {
                node.scrollTop = 0;
            });
        }
    }, [props.sectionIndex]);

    const createList = (tools, labelKey) => {
        if (tools.length === 0) {
            return null;
        }
        const elements = tools.map(tool => <ToolListItem key={tool.key} tool={tool} toggleCheck={props.toggleCheck}/>);

        return (
            <div>
                <div className="checklist-section-header">
                    <p className="label">
                        <Trans i18nKey={labelKey} />
                    </p>
                    <p className="in-box">
                        <Trans i18nKey="tools.inBox" />
                    </p>
                </div>
                <ul>
                    {elements}
                </ul>
            </div>
        )
    }

    const mustHaveElements = createList(props.mustHaveTools, "tools.youWillNeed");
    const mayNeedElements = createList(props.mayNeedTools, "tools.youMayNeed");
    const className = 'checklist' + (props.visible ? ' visible' : '');
    const classNameNextStep = 'btn btn-next-step' + (i18n.language ? ' btn-next-step-lang-' + i18n.language : '');

    return (
        <div
            className={className}
            ref={scrollRef}
        >
            <main>
                <p className="label">
                    <Trans i18nKey="intro.toolsChecklist" />
                </p>
                {mustHaveElements}
                {mayNeedElements}
                <button
                    className={classNameNextStep}
                    onClick={props.nextStep}
                >
                    <Trans i18nKey="intro.nextStep" />
                </button>
            </main>
        </div>
    );

};

export default ChecklistDesktop;
