import React, {
    useEffect,
    useRef
} from 'react';
import { useTranslation } from 'react-i18next';

//  Other
import ConfigurationManager from '../services/configuration';
import {ALL_WIRES, WIRE_TYPES} from '../data/constants';
import {
    exists,
    fixImageSource,
    removeImages,
    hasPostImageElement
} from '../utilities/statics';

const TranslatedListContent = (props) => {

    const { setIsTruncated } = props;

    //  Store ref, so we can replace images, determine if we need to truncate the step,
    //  and scroll to top programmatically
    const contentRef = useRef(null);

    //  Effect hook: If there are images in the HTML, we need to replace them with the embedded images
    //  We need to run this on every render, so there's no guard.
    //  We only run this in ExpandedInfo (that is, non-truncated) mode.
    useEffect(() => {
        if (!exists(setIsTruncated) && contentRef && contentRef.current) {
            //  Effect hook: If there are images in the HTML, we need to replace them with the embedded images
            //  We need to run this on every render, so there's no guard
            fixImageSource(contentRef.current);
        }
    });

    //  Effect hook: determining if we should truncate the step
    //  We only need to run this once, when the component is mounted.
    //  We only run this in mobile (that is, truncated) mode.
    useEffect(() => {
        if (exists(setIsTruncated) && contentRef && contentRef.current) {

            //  This is pretty ugly. We would like to implement this rule:
            //      - If the content has > 5 lines, show 3 of them with an ellipsus, and a button to bring up an overlay
            //      - If there's an image, and then content after the image, same thing
            //  There are two issues:
            //      1) -webkit-line-clamp solves the truncation for <p> elements, but we still need JS
            //          to determine that we have more than 5 lines and apply 3-line truncation.
            //      2) More importantly, <li> elements are not truncated properly. They get clipped, but
            //          they don't get an ellipsus, they're not counted accurately (we may show 4 lines instead of 3),
            //          and their descenders get cut off.
            //  For now, I'm solving the 2nd issue by applying text-overflow to all <li> elements when a StepMobile is truncated,
            //  not just the final visible one.

            const node = contentRef.current;
            if (hasPostImageElement(node)) {
                setIsTruncated(true);
            }
            removeImages(node); // If there are embedded images in the HTML, don't show them here
            if (node.scrollHeight > node.clientHeight) {
                setIsTruncated(true);
            }
        }
    }, [setIsTruncated]);

    const { t } = useTranslation();

    let translatedContent;
    if(exists(props.step) && exists(props.step.wire)) {
        const {isAnalog, isDigitalIqAmericaChime, hasButtonPlusWire, hasBackWire, hasUnlabeledWire} = ConfigurationManager.getHardwareConfiguration();
        const convertKeysToLabels = (wireKeys) => {
            return wireKeys.map(wKey => t(wKey)).join(' | ');
        }
        if (props.step.wire === ALL_WIRES) {
            const chimeType = isAnalog ? t('wires.analog') : t('wires.digital');
            const transLabel = convertKeysToLabels(ConfigurationManager.getLabelKeysFor(WIRE_TYPES.TRANS_WIRE));
            const frontLabel = convertKeysToLabels(ConfigurationManager.getLabelKeysFor(WIRE_TYPES.FRONT_WIRE));
            let backBullet, additionalWireBullet, backWireUsedOrNotUsed, additionalWire;
            let chimeBrandBullet, digitalChimeOutputWires;
            if (hasBackWire) {
                const backLabel = convertKeysToLabels(ConfigurationManager.getLabelKeysFor(WIRE_TYPES.BACK_WIRE));
                backBullet = t('installChime.backBullet', { backLabel })
                // No back wire support for now
                //backWireUsedOrNotUsed = t('installChime.backWireUsed', { backLabel })
                backWireUsedOrNotUsed = t('installChime.wireNotUsed')
            } else {
                backWireUsedOrNotUsed = t('installChime.wireNotUsed')
            }
            if (hasButtonPlusWire || hasUnlabeledWire) {
                const wireLabel = convertKeysToLabels(ConfigurationManager.getLabelKeysFor(WIRE_TYPES.THIRD_WIRE));
                additionalWireBullet = t('installChime.additionalWireBullet', { wireLabel })
                additionalWire = t('installChime.additionalWire', { wireLabel })
            } else {
                additionalWire = t('installChime.wireNotUsed')
            }
            if (isDigitalIqAmericaChime) {
                chimeBrandBullet = t('installChime.chimeBrandIqAmericaBullet')
                digitalChimeOutputWires = t('installChime.digitalChimeOutputWiresIsIqAmerica', {transLabel, frontLabel, additionalWire})
            } else {
                digitalChimeOutputWires = t('installChime.digitalChimeOutputWiresNotIqAmerica', {transLabel, frontLabel, additionalWire})
            }

            translatedContent = t(props.contentKey, {
                chimeType,
                transLabel,
                frontLabel,
                backBullet,
                additionalWireBullet,
                backWireUsedOrNotUsed,
                additionalWire,
                chimeBrandBullet,
                digitalChimeOutputWires
            });
        } else {
            const wireLabels = convertKeysToLabels(ConfigurationManager.getLabelKeysFor(props.step.wire));
            let transWireToChimeKit, frontWireToChimeKit;
            if (isDigitalIqAmericaChime) {
                transWireToChimeKit = t('installChime.transformerIqAmericaCable')
                frontWireToChimeKit = t('installChime.frontIqAmericaCable')
            } else {
                transWireToChimeKit = t('installChime.transformerNormalCable')
                frontWireToChimeKit = t('installChime.frontNormalCable')
            }
            translatedContent = t(props.contentKey, {
                wire0: wireLabels,
                wire1: wireLabels,
                wire2: wireLabels,
                transWireToChimeKit,
                frontWireToChimeKit
            });
        }
    } else {
        translatedContent = t(props.contentKey);
    }

    return (
        <p
            className={props.contentClass}
            ref={contentRef}
            dangerouslySetInnerHTML={{__html: translatedContent}}
        />
    )

}

export default TranslatedListContent;
