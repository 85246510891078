//  Local dependencies
import { exists } from '../utilities/statics';
import { SECTION_TYPES, CHOICE_TYPES } from '../data/constants';

const Configuration = () => {
    let stepData = null;
    let resultData = null;

    let hardwareConfig = {}; // this is set in setStepData()

    /* *****************************************************
     *
     *      PUBLIC
     *
     ***************************************************** */

    const setStepData = (steps) => {
        stepData = steps;
        hardwareConfig = {
            //  All values are boolean unless otherwise noted
            doorbellType: null, // 'standard'|'intercom'|'unknown'
            isAnalog: null,
            isDigitalIqAmericaChime: null,
            hasAdditionalWire: null,
            hasBackWire: null,
            hasBattery: null,
            hasDoorbell: null,
            hasButtonPlusWire: null,
            hasNoFrontWire: null,
            hasNoMinusWire: null,
            hasNoRecognizedWire: null,
            hasNoTrAcWire: null,
            hasNoTransformerWire: null,
            hasUnlabeledWire: null,
            hasWires: null,
            power: null, // 'vaHigh'|'vaLow'|'vaNoRating'|'vaNoTransformer'
            terminalStatus: null, // 'contactSupport'|'fail'|'hirePro'|'success'
            type: null, // 1|2|3
            voltage: null, // 'vMedium'|'vLow'|'vHigh'|'vNoRating'
        };
        if (!exists(steps)) {
            return;
        }
        stepData.forEach((step) => {
            if (exists(step.options)) {
                step.options.forEach((option) => {
                    if (option.checked) {
                        switch (option.type) {
                            case CHOICE_TYPES.TYPE_STANDARD:
                            case CHOICE_TYPES.TYPE_INTERCOM:
                            case CHOICE_TYPES.TYPE_UNKNOWN:
                                hardwareConfig.doorbellType = option.type;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_WIRES:
                            case CHOICE_TYPES.TYPE_NO_WIRES:
                                hardwareConfig.hasWires = option.type === CHOICE_TYPES.TYPE_HAS_WIRES;
                                break;

                            case CHOICE_TYPES.TYPE_ANALOG:
                            case CHOICE_TYPES.TYPE_DIGITAL:
                                hardwareConfig.isAnalog = option.type === CHOICE_TYPES.TYPE_ANALOG;
                                break;

                            case CHOICE_TYPES.TYPE_IS_IQ_AMERICA:
                            case CHOICE_TYPES.TYPE_NOT_IQ_AMERICA:
                                hardwareConfig.isDigitalIqAmericaChime = option.type === CHOICE_TYPES.TYPE_IS_IQ_AMERICA;
                                break;

                            case CHOICE_TYPES.TYPE_1:
                            case CHOICE_TYPES.TYPE_2:
                            case CHOICE_TYPES.TYPE_3:
                                hardwareConfig.type = option.type;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_ADDITIONAL_WIRE:
                            case CHOICE_TYPES.TYPE_NO_ADDITIONAL_WIRE:
                                hardwareConfig.hasAdditionalWire = option.type === CHOICE_TYPES.TYPE_HAS_ADDITIONAL_WIRE;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_BACK_WIRE:
                            case CHOICE_TYPES.TYPE_NO_BACK_WIRE:
                                hardwareConfig.hasBackWire = option.type === CHOICE_TYPES.TYPE_HAS_BACK_WIRE;
                                break;

                            case CHOICE_TYPES.TYPE_BATTERY:
                            case CHOICE_TYPES.TYPE_NO_BATTERY:
                                hardwareConfig.hasBattery = option.type === CHOICE_TYPES.TYPE_BATTERY;
                                break;

                            case CHOICE_TYPES.TYPE_NO_FRONT_WIRE:
                                hardwareConfig.hasNoFrontWire = true;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_BUTTON_PLUS_WIRE:
                            case CHOICE_TYPES.TYPE_NO_BUTTON_PLUS_WIRE:
                                hardwareConfig.hasButtonPlusWire = option.type === CHOICE_TYPES.TYPE_HAS_BUTTON_PLUS_WIRE;
                                break;

                            case CHOICE_TYPES.TYPE_NO_MINUS_WIRE:
                                hardwareConfig.hasNoMinusWire = true;
                                break;

                            case CHOICE_TYPES.TYPE_NO_KNOWN_WIRE:
                                hardwareConfig.hasNoRecognizedWire = true;
                                break;

                            case CHOICE_TYPES.TYPE_NO_TR_AC_WIRE:
                                hardwareConfig.hasNoTrAcWire = true;
                                break;

                            case CHOICE_TYPES.TYPE_NO_TRANSFORMER_WIRE:
                                hardwareConfig.hasNoTransformerWire = true;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_UNLABELED_WIRE:
                            case CHOICE_TYPES.TYPE_NO_UNLABELED_WIRE:
                                hardwareConfig.hasUnlabeledWire = option.type === CHOICE_TYPES.TYPE_HAS_UNLABELED_WIRE;
                                break;

                            case CHOICE_TYPES.TYPE_HAS_DOORBELL:
                            case CHOICE_TYPES.TYPE_NO_DOORBELL:
                                hardwareConfig.hasDoorbell = option.type === CHOICE_TYPES.TYPE_HAS_DOORBELL;
                                break;

                            case CHOICE_TYPES.TYPE_POWER_HIGH:
                            case CHOICE_TYPES.TYPE_POWER_LOW:
                            case CHOICE_TYPES.TYPE_POWER_NO_RATING:
                            case CHOICE_TYPES.TYPE_POWER_NO_TRANSFORMER:
                                hardwareConfig.power = option.type;
                                break;

                            case CHOICE_TYPES.TYPE_VOLTAGE_MEDIUM:
                            case CHOICE_TYPES.TYPE_VOLTAGE_LOW:
                            case CHOICE_TYPES.TYPE_VOLTAGE_HIGH:
                            case CHOICE_TYPES.TYPE_VOLTAGE_NO_RATING:
                                hardwareConfig.voltage = option.type;
                                break;

                            default:
                                break;

                        }
                    }
                })
            }
        })
        const filteredSteps = getSteps();
        const lastStep = filteredSteps[filteredSteps.length - 1];
        if (isTerminalStep(lastStep)) {
            hardwareConfig.terminalStatus = lastStep.buttonConfig;
        }
    }

    const getHardwareConfiguration = () => hardwareConfig;

    const setResultData = results => resultData = results;

    const hasOptions = (step) => {
        return exists(step.options) && step.options.length > 0;
    }

    const getSectionType = (steps) => {
        const {NORMAL, BRANCHING, RESULTS} = SECTION_TYPES;
        return steps.reduce((accumulator, currentStep) => {
            if (hasOptions(currentStep)) {
                if (accumulator === RESULTS) {
                    throw new Error(`A section can't be both BRANCHING and RESULTS. steps: ${JSON.stringify(steps)} `);
                } else {
                    return BRANCHING;
                }
            } else if (hasResults(currentStep)) {
                if (accumulator === BRANCHING) {
                    throw new Error(`A section can't be both BRANCHING and RESULTS. steps: ${JSON.stringify(steps)} `);
                } else {
                    return RESULTS;
                }
            } else {
                return accumulator;
            }
        }, NORMAL);
    }

    const getSteps = () => {
        if (!exists(stepData)) {
            throw new Error('No step data has been set.');
        }
        //  Send all steps, up to and including the first branching step
        //  that has not yet been set
        let foundUncheckedBranch = false;
        let nextBranchName = '';
        return stepData.filter((step) => {
            if (foundUncheckedBranch) {
                return false;
            }
            if (isTerminalStep(step)) {
                return step.branchName === nextBranchName;
            }
            if (exists(step.isAnalog) && step.isAnalog !== hardwareConfig.isAnalog) {
                return false;
            }
            if (!exists(step.options)) {
                if (nextBranchName.length === 0 || step.branchName === nextBranchName) {
                    nextBranchName = '';
                    return true;
                }
                return false; // We're skipping this non-branching step
            }
            //  It's a branching step.
            if (nextBranchName.length === 0 || step.branchName === nextBranchName) {
                //  It's on the path. If it points to the next step, keep going.
                //  If not, we're done.
                step.options.forEach((option) => {
                    //  Options are essentially radio buttons. We're guaranteed that only 0 or 1 will be checked.
                    if (option.checked) {
                        nextBranchName = option.nextStep;
                    }
                })
                foundUncheckedBranch = nextBranchName.length === 0;
                return true;
            }
            return false; // this should never run
        });
    }

    // TODO: no unit test for getIndexFor()
    const getIndexFor = (branchName) => {
        return getSteps().findIndex(step => step.branchName === branchName);
    }

    // TODO: no unit test for getStepFor()
    const getStepFor = (index) => {
        return getSteps()[index];
    }

    // TODO: no unit test for atLastStep()
    const atLastStep = (index) => {
        const steps = getSteps();
        if (index > steps.length - 1) {
            throw new Error(`atLastStep called with illegal index ${index} for steps ${JSON.stringify(steps)}`);
        }
        return isTerminalStep(steps[index]);
    }

    const getResults = () => {
        if (!exists(resultData)) {
            throw new Error('No result data has been set.');
        }
        const filteredResults = resultData.filter((step) => {
            if (!exists(step.hasBackWire) && !exists(step.typemask)) {
                return true;
            }
            if (exists(step.hasBackWire) && hardwareConfig.hasBackWire) {
                return true;
            }
            if (exists(step.typemask)) {
                const index = 1 << (hardwareConfig.type - 1);
                const matchesMask = exists(step.typemask) && ((step.typemask & index) === index);
                return matchesMask;
            }
            return false; // this should never run
        });
        return filteredResults;
    }

    const getLabelKeysFor = (wire, type = hardwareConfig.type) => {
        const wireLabelKeys = {
            '10': ['wires.trans0', 'wires.trans1', 'wires.trans2', 'wires.trans3', 'wires.trans4', 'wires.trans5'],
            '11': ['wires.front0', 'wires.front1', 'wires.front2'],
            //  There is no '12'; type 1 doesn't support a 3rd wire
            '13': ['wires.back0', 'wires.back1', 'wires.back2', 'wires.back3'],
            '20': ['wires.plus0', 'wires.plus1'],
            '21': ['wires.minus0', 'wires.minus1'],
            '22': ['wires.buttonPlus0', 'wires.buttonPlus1'],
            '23': ['wires.back0', 'wires.back1', 'wires.back2', 'wires.back3'],
            '30': ['wires.batt'],
            '31': ['wires.tr_ac'],
            '32': ['wires.unlabeled'],
            '33': ['wires.back0', 'wires.back1', 'wires.back2', 'wires.back3'],
        };
        return wireLabelKeys[`${type}${wire}`];
    }

    const userCanAdvance = () => {
        return hardwareConfig.terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_SUCCESS_OWNS_DOORBELL
            || hardwareConfig.terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_SUCCESS_BUY_DOORBELL
            || hardwareConfig.terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_HIRE_PRO;
    }



    /* *****************************************************
     *
     *      PRIVATE
     *
     ***************************************************** */

    const hasResults = (step) => {
        return exists(step.typemask);
    }

    const isTerminalStep = (step) => {
        return exists(step.buttonConfig);
    }

    /* *****************************************************
     *
     *      EXPORT
     *
     ***************************************************** */

    return {
        atLastStep,
        getHardwareConfiguration,
        getIndexFor,
        getLabelKeysFor,
        getResults,
        getSectionType,
        getStepFor,
        getSteps,
        hasOptions,
        setResultData,
        setStepData,
        userCanAdvance
    }
}

//  Make a singleton
const ConfigurationManager = Configuration();

export default ConfigurationManager;
