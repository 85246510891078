import React from 'react';

const Header = (props) => {
    return (
        <header className={`header${props.visible ? "" : " hidden"}`}>
            <button
                className="hamburger"
                onClick={() => props.launchMenu()}
            ></button>
        </header>
    );
}

export default Header;
