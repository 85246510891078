import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import { Trans } from 'react-i18next';
import i18n from "../services/i18n";

//  Components
import Checklist from './ChecklistMobile';
import SectionHeader from './SectionHeader';
import StepCollection from './StepCollection';

//  Other
import ConfigurationManager from '../services/configuration';
import { SECTION_TYPES } from '../data/constants';
import { getScrollDiv } from '../utilities/statics';

const SectionScreen = (props) => {
    //  Store ref, so we can scroll to top or bottom programmatically
    const scrollRef = useRef(null);

    /* ****************************************************************
     *      State hooks
    **************************************************************** */

    //  Store whether the screen needs a "Back to top" button
    const [screenCanScroll, setScreenCanScroll] = useState(false);


    /* ****************************************************************
     *      Branching (must be defined before the effect hooks)
    **************************************************************** */

    const visibleSteps = ConfigurationManager.getSectionType(props.section.steps) === SECTION_TYPES.RESULTS
        ? ConfigurationManager.getResults()
        : props.section.steps;


    /* ****************************************************************
     *      Methods
    **************************************************************** */

    const scrollToTarget = (target = 0, animate = true) => {
        if (animate) {
            getScrollDiv(scrollRef.current).scrollTo({ top: target, behavior: 'smooth' });
        } else {
            getScrollDiv(scrollRef.current).scrollTo({ top: target });
        }
    }

    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  If the user scrolls the checklist down and then goes to another section,
    //  we don't want it to remain scrolled.
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            //  Since display is toggled from 'none'->'block', we need to wait for it to be displayed
            //  before we can accurately reset the scrolling. We use requestAnimationFrame().
            requestAnimationFrame(() => {
                scrollToTarget(0, false);
                setScreenCanScroll(scrollRef.current.clientHeight > window.innerHeight);
            });
        }
    }, [props.sectionIndex]);  // eslint-disable-line react-hooks/exhaustive-deps


    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    const classNameNextStep = 'btn btn-next-step' + (i18n.language ? ' btn-next-step-lang-' + i18n.language : '');

    const nextStepButton = props.atLastSection
        ? null
        : (<button
            className={classNameNextStep}
            onClick={props.selectNextSection}
        >
            <Trans i18nKey="menu.next" />
        </button>);

    const scrollToTopButton = screenCanScroll
        ? (<button
            className="btn btn-start-over"
            onClick={scrollToTarget}
        >
            <Trans i18nKey="menu.backToTop" />
        </button>)
        : null;

    return (
        <div
            className="section-container"
            ref={scrollRef}
        >
            <SectionHeader {...props} />
            <main>
                <Checklist
                    mustHaveTools={props.section.mustHaveTools}
                    mayNeedTools={props.section.mayNeedTools}
                    toggleCheck={props.toggleCheck}
                />
                <StepCollection
                    steps={visibleSteps}
                    isMobile={true}
                    launchInfo={props.launchInfo}
                    toggleCheck={props.toggleCheck}
                    selectOption={props.selectOption}
                    isBranching={props.isBranching}
                />
                {nextStepButton}
                {scrollToTopButton}
            </main>
        </div>
    );
};

export default SectionScreen;
