import React from 'react';
import { Trans } from 'react-i18next';

//  Other
import checkedMark from '../assets/checkmark.svg';
import { generateNumberedCheckbox, exists } from '../utilities/statics';

const StepHeader = (props) => {
    const { labelKey, labelClassOverride, checked } = props.step;

    const stepClass = `step${checked ? " checked" : ""}${props.isNumbered ? "" : " tool-icon"}${props.focus ? " focused" : ""}`;
    const labelClass = labelClassOverride ? labelClassOverride : "label";

    const checkboxImage = checked
        ? checkedMark
        : generateNumberedCheckbox(props.index + 1);

    const checkboxStyle = props.isNumbered
        ? {'backgroundImage': `url(${checkboxImage})`}
        : null;

    return (
        <div
            className={stepClass}
            onClick={exists(props.launchInfo) ? () => props.launchInfo(): null}
        >
            <header>
                <p className={labelClass}>
                    <Trans i18nKey={labelKey} />
                </p>
                <input
                    name="completed"
                    type="checkbox"
                    checked={checked}
                    onChange={() => props.toggleCheck(props.step)}
                    style={checkboxStyle}
                />
            </header>
            {props.children}
        </div>
    );
};

export default StepHeader;
