import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';

//  Local data
import { URL } from '../data/constants';

//  Other
import { obj2Array, exists } from '../utilities/statics';


const history = createBrowserHistory();

const RouterContext = React.createContext({
    route: history.location.pathname,
});

const Router = (props) => {
    const routesArray = obj2Array(props.routes);
    const getPath = (location) => {
        const USING_HASH = true;
        return USING_HASH && location.hash.length > 0
            ? location.hash
            : location.pathname;
    }

    const [route, setRoute] = useState(getPath(history.location));
    const [internal, setInternal] = useState(false);

    //  Mounting/unmounting
    useEffect(() => {
        const unlisten = history.listen((location) => {
            const newRoute = getPath(location);
            setInternal(exists(location.state) && exists(location.state.internal) && location.state.internal);
            setRoute(newRoute);
        });
        return () => {
            console.log('Router: UNMOUNTED');
            unlisten();
        }
        //  TODO: this generates a warning, but I'm not satisfied with any of the answers in
        //  https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
        //  I'm disabling the warning for now.
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    // Rendering
    const { children } = props;
    // Check if no route matched
    const is404 = routesArray.indexOf(route) === -1;
    if (is404) {
        history.push(URL.LANDING);
        return null;
    }
    // Create our RouterContext value.
    const routerContextValue = { route, internal };
    return (
        <RouterContext.Provider value={routerContextValue}>
            {children}
        </RouterContext.Provider>
    );
}


export { history, RouterContext, Router }
