import React, {
    useEffect,
    useRef,
    useState
} from 'react';

//  Components
import SectionHeader from './SectionHeader';
import StepCollection from './StepCollection';
import ButtonFooter from './ButtonFooter';

//  Other
import ConfigurationManager from '../services/configuration';
import { getScrollDiv } from '../utilities/statics';

const CompatibilityScreen = (props) => {
    //  Store ref, so we can scroll to top or bottom programmatically
    const scrollRef = useRef(null);

    /* ****************************************************************
     *      State hooks
    **************************************************************** */

    //  We save the branching steps as state data, so that we can force an update
    //  when the user selects an option.
    const [visibleSteps, setVisibleSteps] = useState([]);
    // console.log(`visibleSteps: `, visibleSteps, '; props.section.steps: ', props.section.steps);


    /* ****************************************************************
     *      Methods
    **************************************************************** */

    const scrollToTarget = (target = 0, animate = true) => {
        if (animate) {
            getScrollDiv(scrollRef.current).scrollTo({ top: target, behavior: 'smooth' });
        } else {
            getScrollDiv(scrollRef.current).scrollTo({ top: target });
        }
    }

    const selectOption = (selectedOption) => {
        const index = ConfigurationManager.getIndexFor(selectedOption.branchName);

        if (scrollRef && scrollRef.current) {
            const nextIndex = index + 1;
            //  Wait for screen to redraw, before targeting the proper step. We use requestAnimationFrame() for this.
            requestAnimationFrame(() => {
                const steps = scrollRef.current.querySelectorAll('.step');
                if (steps.length > nextIndex) {
                    scrollToTarget(steps[nextIndex].offsetTop);
                }
            });
        }
        props.selectOption(selectedOption);
    }

    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  Whenever the user makes a selection, render the new branching data.
    useEffect(() => {
        setVisibleSteps(ConfigurationManager.getSteps());
    }, [props.section.steps]);  // eslint-disable-line react-hooks/exhaustive-deps

    //  When the user navigates to the screen, make sure it's scrolled to the top.
    useEffect(() => {
        if (scrollRef && scrollRef.current) {
            //  Since display is toggled from 'none'->'block', we need to wait for it to be displayed
            //  before we can accurately reset the scrolling. We use requestAnimationFrame().
            requestAnimationFrame(() => {
                scrollToTarget(0, false);
            });
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    return (
        <div
            className="section-container"
            ref={scrollRef}
        >
            <SectionHeader {...props} />
            <main>
                <StepCollection
                    steps={visibleSteps}
                    isMobile={true}
                    launchInfo={props.launchInfo}
                    toggleCheck={props.toggleCheck}
                    selectOption={selectOption}
                    isBranching={props.isBranching}
                />
                <ButtonFooter
                    selectNextSection={props.selectNextSection}
                    startOver={props.startOver}
                />
            </main>
        </div>
    );
};

export default CompatibilityScreen;
