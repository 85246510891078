import React, { useEffect, useState } from 'react';

//  Components
import SectionHeader from './SectionHeader';
import ExpandedInfo from './ExpandedInfo';
import StepCollection from './StepCollection';
import ButtonFooter from './ButtonFooter';

//  Other
import { exists } from '../utilities/statics';
import ConfigurationManager from '../services/configuration';

const CompatibilityScreen = (props) => {

    const { steps } = props.section;

    /* ****************************************************************
     *      State hooks
    **************************************************************** */

    const [expandedStep, setExpandedStep] = useState(0); // The data we currently display
    const [expandedIndex, setExpandedIndex] = useState(null); // The index for that data, into the section.steps array

    //  We save the branching steps as state data, so that we can force an update
    //  when the user selects an option.
    const [visibleSteps, setVisibleSteps] = useState([]);

    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  Whenever the user makes a selection, render the new branching data.
    useEffect(() => {
        setVisibleSteps(ConfigurationManager.getSteps());
    }, [steps]);  // eslint-disable-line react-hooks/exhaustive-deps

    //  Whenever we navigate to this screen, show the first step
    useEffect(() => {
        launchInfo(visibleSteps[0], 0);
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    //  If the props change, render the expandedInfo for the selected step.
    useEffect(() => {
        if (exists(expandedIndex)) {
            setExpandedStep(ConfigurationManager.getStepFor(expandedIndex));
        }
    }, [steps, expandedIndex]);  // eslint-disable-line react-hooks/exhaustive-deps


    /* ****************************************************************
     *      Methods
    **************************************************************** */

    const launchInfo = (step, index) => {
        setExpandedStep(step);
        setExpandedIndex(index);
    }

    const atLastStep = () => {
        if (!exists(expandedIndex)) {
            return false;
        }
        return ConfigurationManager.atLastStep(expandedIndex);
    }

    const nextStep = () => {
        if (!atLastStep()) {
            const nextIndex = expandedIndex + 1;
            const nextStep = visibleSteps[nextIndex];
            launchInfo(nextStep, nextIndex);
        }
    }

    const nextStepAvailable = () => {
        return (expandedIndex < visibleSteps.length - 1);
    }

    const nextStepFunction = atLastStep()
        ? null
        : nextStepAvailable()
            ? nextStep
            : null;

    const buttonFooter = atLastStep()
        ? (<ButtonFooter
                selectNextSection={props.selectNextSection}
                startOver={props.startOver}
            />)
        : null;


    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    return (
        <div className="section-container">
            <SectionHeader {...props} />
            <main>
                <div className="column-left">
                    <StepCollection
                        steps={visibleSteps}
                        isMobile={false}
                        expandedStep={expandedStep}
                        launchInfo={launchInfo}
                        toggleCheck={(step) => props.toggleCheck(step, true)}
                        toolsStep={null}
                    />
                </div>
                <div className="column-right">
                    <ExpandedInfo
                        step={expandedStep}
                        index={expandedIndex}
                        closeInfo={() => {}} // no-op
                        nextStep={nextStepFunction}
                        visible={true}
                        selectOption={props.selectOption}
                        canClose={false}
                    />
                    {buttonFooter}
                </div>
            </main>
        </div>
    );
};

export default CompatibilityScreen;
