import React from 'react';
import { useTranslation } from 'react-i18next';

import { getImageSource } from '../utilities/statics';

const ToolListItem = (props) => {
    const { t } = useTranslation();
    const { tool } = props;

    let label = t(tool.key);
    if (tool.included) {
        label = `${label}*`;
    }
    return (
        <li
            key={tool.key}
            className={tool.checked ? "checked" : ""}
        >
            <img
                src={getImageSource(tool.image)}
                className="tool-image"
                alt={tool.image}
            />
            <p className="item-name">{label}</p>
            <input
                name="hasTool"
                type="checkbox"
                checked={JSON.parse(tool.checked)}
                onChange={() => props.toggleCheck(tool)}
            />
        </li>);
};

export default ToolListItem;
