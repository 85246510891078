import React from 'react';

//  Components
import StepDesktop from './StepHeader';
import StepMobile from './StepMobile';

//  Other
import { exists } from '../utilities/statics';

const StepCollection = (props) => {
    const stepElements = exists(props.steps)
        ? props.steps.map((step, index) => {
            return props.isMobile
                ? (<StepMobile
                    key={step.labelKey}
                    index={index}
                    step={step}
                    launchInfo={() => props.launchInfo(step)}
                    toggleCheck={props.toggleCheck}
                    isNumbered={true}
                    selectOption={props.selectOption}
                    isBranching={props.isBranching}
                />)
                : (<StepDesktop
                    key={step.labelKey}
                    index={index}
                    step={step}
                    launchInfo={() => props.launchInfo(step, index)}
                    toggleCheck={props.toggleCheck}
                    focus={step === props.expandedStep}
                    isNumbered={true}
                    selectOption={props.selectOption}
                />)})
        : null
    ;

    //  If a tools step exists, prepend it
    if(exists(stepElements) && exists(props.toolsStep)) {
        stepElements.unshift(props.toolsStep);
    }

    return stepElements;
}

export default StepCollection;
