import React from 'react';
import { Trans } from 'react-i18next';

//  Other
import { exists } from '../utilities/statics';

const SectionHeader = (props) => {
    const { numSections, sectionIndex } = props;
    const { labelKey } = props.section;

    const sectionCounter = exists(numSections)
        ? (
            <p className="section-number">
                <Trans
                    i18nKey="menu.section"
                    values={{ count:`${sectionIndex + 1}/${numSections}` }}
                />
            </p>
        )
        : null;

    return (
        <header>
            { sectionCounter }
            <p className="section-title">
                <Trans i18nKey={labelKey} />
            </p>
        </header>
    );
};

export default SectionHeader;
