import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

//  Other
import {goToLink} from '../utilities/statics';
import {LINKS} from '../data/constants';

const MenuOverlay = (props) => {
    const { t } = useTranslation();

    const options = props.labels.map((label, index) => {
        return (<li
            key={label.labelKey}
            className={label.completed ? "checked" : ""}
            onClick={() => props.selectSection(index)}
        >
            <div>
                {t(label.labelKey)}
                <input
                    name="completed"
                    type="checkbox"
                    checked={label.completed}
                    onChange={() => {}} // no-op
                />
            </div>
        </li>);
    });

    const className = 'menu-overlay ' + (props.visible ? 'visible' : '');

    return (
        <div className={className}>
            <header>
                <button
                    className="close-button"
                    onClick={() => props.closeMenu()}
                ></button>
            </header>
            <main>
                <div className="menu-container">
                    <p className="menu-heading"><Trans i18nKey="menu.preInstall" /></p>
                    <ul>
                        <li
                            className={`compatibility ${props.compatibilityCompleted ? "checked" : ""}`}
                            onClick={() => props.showCompatibility()}
                        >
                            <div>
                                <Trans i18nKey="compatibilityCheck.mainLabel" />
                                <input
                                    name="completed"
                                    type="checkbox"
                                    checked={props.compatibilityCompleted}
                                    onChange={() => {}} // no-op
                                />
                            </div>
                        </li>
                        <li
                            className={`tools ${props.toolsCompleted ? "checked" : ""}`}
                            onClick={() => props.showTools()}
                        >
                            <div>
                                <Trans i18nKey="intro.toolsChecklist" />
                                <input
                                    name="completed"
                                    type="checkbox"
                                    checked={props.toolsCompleted}
                                    onChange={() => {}} // no-op
                                />
                            </div>
                        </li>
                    </ul>
                    <p className="menu-heading"><Trans i18nKey="menu.installationGuide" /></p>
                    <ol>
                        {options}
                    </ol>
                    <p className="menu-heading"><Trans i18nKey="menu.help" /></p>
                    <ul>
                        <li
                            className="pro"
                            onClick={props.hirePro}>
                            <Trans i18nKey="menu.hirePro" />
                        </li>
                        <li
                            className="support"
                            onClick={() => goToLink(LINKS.SUPPORT)}>
                            <Trans i18nKey="menu.productSupport" />
                        </li>
                    </ul>
                </div>
            </main>
        </div>
    );
}

export default MenuOverlay;
