import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import iNoBounce from 'inobounce';  // eslint-disable-line no-unused-vars
import 'scroll-behavior-polyfill';

//  Components
import Landing from './components/Landing';
import ToolList from './components/ToolList';
import Overview from './components/Overview';
import SectionScreen from './components/SectionScreen';
import Header from './components/Header';
import Menu from './components/MenuOverlay';
import ExpandedInfo from './components/ExpandedInfo';
import Modal from './components/Modal';

//  Other (services, data, etc.)
import i18n from './services/i18n';
import APP_DATA from './data/steps';
import {
    LANDING_SCREEN_INDEX,
    COMPATIBILITY_SCREEN_INDEX,
    TOOLS_SCREEN_INDEX,
    OVERVIEW_SCREEN_INDEX,
    SECTION_STORAGE_KEY,
    COMPATIBILITY_STORAGE_KEY,
    TOOL_LIST_STORAGE_KEY,
    VERSION_STORAGE_KEY,
    SECTION_SCREEN_INDEX,
    URL,
    SECTION_FOR_URL,
    SCREEN_FOR_URL,
    SECTION_TYPES,
    MODAL_TYPES,
    LINKS,
    TITLE_KEYS
} from './data/constants';
import { isMobile, isLandscape } from './services/breakpoints';
import { RouterContext, history } from './services/router';
import ConfigurationManager from './services/configuration';
import {
    contains,
    exists,
    goToLink
} from './utilities/statics';

const App = () => {
    // iNoBounce.enable();  // Enabling this causes a bug on Android, where the screen won't scroll.
                            // Importing it is sufficient to work.

    const { t } = useTranslation();

    /* ****************************************************************
     *      Methods called on initialization (have to be defined before init is called)
    **************************************************************** */

    const storeSectionData = (sectionData = sections) => {
        localStorage.setItem(SECTION_STORAGE_KEY, JSON.stringify(sectionData));
        storeResultData(sectionData);
    }
    const storeToolList = (toollist) => {
        localStorage.setItem(TOOL_LIST_STORAGE_KEY, JSON.stringify(toollist));
    }
    const storeVersionNumber = (versionNumber) => {
        localStorage.setItem(VERSION_STORAGE_KEY, JSON.stringify(versionNumber));
    }
    const storeCompatibilityData = (data) => {
        localStorage.setItem(COMPATIBILITY_STORAGE_KEY, JSON.stringify(data));
        ConfigurationManager.setStepData(data.steps);
    }
    const storeResultData = (sectionData = sections) => {
        sectionData.forEach((section) => {
            const sectionType = ConfigurationManager.getSectionType(section.steps);
            if (sectionType === SECTION_TYPES.RESULTS) {
                ConfigurationManager.setResultData(section.steps);
            }
        });
    }

    const createToolLists = (sections) => {
        const createToolList = (mustHave) => {
            const keys = [];
            return sections
                .reduce((accum, section) => {
                    const sectionTools = section[mustHave ? 'mustHaveTools' : 'mayNeedTools'];
                    if (exists(sectionTools)) {
                        accum = accum.concat(sectionTools);
                    }
                    return accum;
                }, [])
                .filter((tool) => {
                    if (contains(keys, tool.key)) {
                        return false;
                    }
                    keys.push(tool.key);
                    return true;
                })
                .map((tool) => {
                    return {...tool, mustHave};
                });
        }
        const mustHaveTools = createToolList(true);
        const mayNeedTools = createToolList(false);
        return {mustHaveTools, mayNeedTools};
    }


    /* ****************************************************************
     *      Initialization: must occur before state is set
    **************************************************************** */

    //  Initialize data: read it from local storage if it exists, or set it from JSON
    if (!localStorage.getItem(SECTION_STORAGE_KEY) || !localStorage.getItem(COMPATIBILITY_STORAGE_KEY) || !localStorage.getItem(TOOL_LIST_STORAGE_KEY)|| !localStorage.getItem(VERSION_STORAGE_KEY)) {
        //  We don't have data in local storage. Set it from JSON.
        storeSectionData(APP_DATA.sections);
        storeCompatibilityData(APP_DATA.compatibilityCheck);
        storeVersionNumber(APP_DATA.version);
        storeToolList(createToolLists(APP_DATA.sections));
    } else if (APP_DATA.version > parseFloat(localStorage.getItem(VERSION_STORAGE_KEY))) {
        //  The data has changed. Replace the locally-stored data with the version from the file.
        storeSectionData(APP_DATA.sections);
        storeCompatibilityData(APP_DATA.compatibilityCheck);
        storeVersionNumber(APP_DATA.version);
        storeToolList(createToolLists(APP_DATA.sections));
    } else {
        //  Even though we have the data, we still need to call storeCompatibilityData()
        //  and storeResultData() to populate the ConfigurationManager
        storeCompatibilityData(JSON.parse(localStorage.getItem(COMPATIBILITY_STORAGE_KEY)));
        storeResultData(JSON.parse(localStorage.getItem(SECTION_STORAGE_KEY)));
    }


    /* ****************************************************************
     *      Effect hooks
    **************************************************************** */

    //  Change the screen, based on the route
    const { route, internal } = React.useContext(RouterContext);
    useEffect(() => {
        if (route === URL.LANDING) {
            advanceToScreen(LANDING_SCREEN_INDEX);
        } else if (route === URL.COMPATIBILITY) {
            advanceToScreen(COMPATIBILITY_SCREEN_INDEX);
        } else if (route === URL.INSTALL_ITEMS) {
            advanceToScreen(TOOLS_SCREEN_INDEX);
        } else if (!ConfigurationManager.userCanAdvance()) {
            navigateToScreen(COMPATIBILITY_SCREEN_INDEX); // navigateToScreen() calls history.push, while advanceToScreen doesn't
        } else if (route === URL.OVERVIEW) {
            advanceToScreen(OVERVIEW_SCREEN_INDEX);
        } else {
            const index = SECTION_FOR_URL.indexOf(route);
            selectSection(index);

            if (!internal) {
                //  If the user arrived here from a URL link,
                //  set all steps for any previous sections.
                //  TODO: quick history fix, so that refreshing section 6
                //  TODO: doesn't cause section 5 to be checked off. Seems to work.
                //  TODO: If it stands up to testing, keep it.
                if (!exists(window.history.state)) {
                    let newSections = sections;
                    for (let i = 0; i < index; i++) {
                        newSections = setAllSteps(i, newSections);
                    }
                } else {
                    storeSectionData(); // populate the ConfigurationManager
                }
            }
        }

        //  Change the document title, to match the route
        if (route === URL.LANDING) {
            document.title = t('url.title');
        } else {
            document.title = `${t('url.title')}: ${t(TITLE_KEYS[route])}`;
        }

        //  TODO: this generates a warning, but I'm not satisfied with any of the answers in
        //  https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
        //  I'm disabling the warning for now.
    }, [route]);  // eslint-disable-line react-hooks/exhaustive-deps

    /* ****************************************************************
     *      State (has to be set after initialization
    **************************************************************** */

    const [sectionIndex, setSectionIndex] = useState(0);
    const [screenIndex, setScreenIndex] = useState(LANDING_SCREEN_INDEX);
    const [menuVisible, setMenuVisible] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);
    const [expandedStep, setExpandedStep] = useState(null);
    const [modalType, setModalType] = useState(MODAL_TYPES.HIDDEN);
    const [sections, setSections] = useState(JSON.parse(localStorage.getItem(SECTION_STORAGE_KEY)));
    const [tools, setTools] = useState(JSON.parse(localStorage.getItem(TOOL_LIST_STORAGE_KEY)));
    const [compatibility, setCompatibility] = useState(JSON.parse(localStorage.getItem(COMPATIBILITY_STORAGE_KEY)));

    /* ****************************************************************
     *      Methods
    **************************************************************** */

    const selectSection = (index) => {
        if (index < 0 || index > sections.length - 1) {
            throw new Error(`${index} is out of range`);
        }
        setSectionIndex(index);
        setMenuVisible(false);
        setScreenIndex(SECTION_SCREEN_INDEX);
    }

    const navigateToSection = (index) => {
        const url = SECTION_FOR_URL[index];
        history.push(url, {internal: true});
    }

    const advanceToScreen = (screenIndex) => {
        setScreenIndex(screenIndex);
        setMenuVisible(false);
    }

    const navigateToScreen = (screenIndex, allowRedirect = true) => {
        closeMenu();

        let url;
        //  If the user tries to advance from the Landing screen (allowRedirect === true),
        //      we may skip the Compat/Tools screens (depending on what the user has completed).
        //  However, if the user selects Compat/Tools from the Menu (allowRedirect === false),
        //      go to those screens, regardless of state.
        if (allowRedirect && screenIndex === COMPATIBILITY_SCREEN_INDEX && ConfigurationManager.userCanAdvance()) {
            if (allToolsAreChecked()) {
                //  Skip the Compatibility and Item lists
                url = SCREEN_FOR_URL[OVERVIEW_SCREEN_INDEX];
            } else {
                //  Skip the Compatibility list, and go to the Item list
                url = SCREEN_FOR_URL[TOOLS_SCREEN_INDEX];
            }
        } else {
            url = SCREEN_FOR_URL[screenIndex];
        }
        history.push(url, {internal: true});
    }

    const atLastSection = () => {
        return sectionIndex === sections.length - 1;
    }

    const selectNextSection = () => {
        if (!atLastSection()) {
            navigateToSection(sectionIndex + 1)
        }
    }

    const launchMenu = () => {
        setMenuVisible(true);
    }

    const closeMenu = () => {
        setMenuVisible(false);
    }

    const launchInfo = (step) => {
        setExpandedStep(step);
        setInfoVisible(true);
    }

    const closeInfo = () => {
        setInfoVisible(false);
    }

    //  TODO: App::toggleCheck() is a little fragile (tightly coupled to the JSON structure); it should be more generic.
    const toggleCheck = (step, isBranching = false) => {
        // console.log('toggleCheck: ', step, '; isBranching: ', isBranching);
        if (isBranching) {
            const updatedData = JSON.parse(JSON.stringify(compatibility)); // make a clone
            const updatedStep = updatedData.steps.find(newStep => step.branchName === newStep.branchName);
            updatedStep.checked = !updatedStep.checked;
            setCompatibility(updatedData);
            storeCompatibilityData(updatedData);

        } else {
            //  We don't want to mutate state directly, so we map()
            const updatedSections = sections.map((section) => {
                const mustHaveTools = section.mustHaveTools.map((mustHaveTool) => {
                    if (mustHaveTool.key === step.key) {
                        return {
                            ...mustHaveTool,
                            checked: !mustHaveTool.checked
                        };
                    } else {
                        return {...mustHaveTool};
                    }
                });
                const mayNeedTools = section.mayNeedTools.map((mayNeedTool) => {
                    if (mayNeedTool.key === step.key) {
                        return {
                            ...mayNeedTool,
                            checked: !mayNeedTool.checked
                        };
                    } else {
                        return {...mayNeedTool};
                    }
                });
                const steps = section.steps.map((newStep) => {
                    if (newStep.key === step.key) {
                        // console.log('toggling: ', newStep.checked);
                        return {
                            ...newStep,
                            checked: !newStep.checked
                        };
                    } else {
                        return {...newStep};
                    }
                });
                const completed = steps.reduce((accumulator, currentStep) => {
                    return accumulator && currentStep.checked;
                }, true);
                return {
                    ...section, ...{completed},
                    mustHaveTools,
                    mayNeedTools,
                    steps
                };
            });

            // console.log('updatedSections: ', updatedSections)
            setSections(updatedSections);
            storeSectionData(updatedSections);
        }
    }

    const selectOption = (selectedOption) => {
        // console.log('selectedOption: ', selectedOption);
        const updatedData = JSON.parse(JSON.stringify(compatibility)); // make a clone
        const step = updatedData.steps.find(step => step.branchName === selectedOption.branchName);
        step.options.forEach(option => option.checked = option.type === selectedOption.type);
        step.checked = true;
        //  If a user backtracks (changes an existing selection), we need to clear
        //  all succeeding choices
        const startIndex = updatedData.steps.indexOf(step);
        for(let i = startIndex + 1; i < updatedData.steps.length; i++) {
            const nextStep = updatedData.steps[i];
            if (ConfigurationManager.hasOptions(nextStep)) {
                nextStep.options.forEach(option => option.checked = false);
            }
            nextStep.checked = false;
        }
        // console.log('updatedData: ', updatedData);
        setCompatibility(updatedData);
        storeCompatibilityData(updatedData);
        // const config = ConfigurationManager.getHardwareConfiguration();
        // console.log('config: ', config)
    }

    const toggleTool = (selectedTool) => {
        const updatedTools = JSON.parse(JSON.stringify(tools)); // make a clone
        const toolList = updatedTools[selectedTool.mustHave ? 'mustHaveTools' : 'mayNeedTools'];
        const tool = toolList.find(t => t.key === selectedTool.key);
        tool.checked = !tool.checked;
        setTools(updatedTools);
        storeToolList(updatedTools);
    }

    //  TODO: using reload() was a quick-fix. Is it the right thing to do?
    const clearAllSteps = (goToLanding = true) => {
        //  Clear data first. It will get reset after the reload().
        localStorage.removeItem(SECTION_STORAGE_KEY);
        localStorage.removeItem(COMPATIBILITY_STORAGE_KEY);
        localStorage.removeItem(TOOL_LIST_STORAGE_KEY);
        closeModal();

        if (goToLanding) {
            navigateToScreen(LANDING_SCREEN_INDEX);
        } else {
            window.location.reload();
        }
    }

    const setAllSteps = (newIndex = sectionIndex, newSections = sections, toChecked = true) => {
        //  We don't want to mutate state directly, so we map()
        const updatedSections = newSections.map((section, index) => {
            const steps = index === newIndex
                ? section.steps.map((newStep) => {
                    return {...newStep, checked: toChecked};
                })
                : section.steps.map((newStep) => { return {...newStep}});
            const completed = index === newIndex ? toChecked : section.completed;
            return {...section, completed: completed, steps};
        });

        setSections(updatedSections);
        storeSectionData(updatedSections);
        return updatedSections;
    }

    const allToolsAreChecked = () => {
        const mustHaveToolsChecked = tools['mustHaveTools'].reduce((accum, tool) => {
            return tool.checked && accum;
        }, true);
        return tools['mayNeedTools'].reduce((accum, tool) => {
            return tool.checked && accum;
        }, mustHaveToolsChecked);
    }

    const showModal = (type) => {
        setModalType(type);
    }

    const closeModal = () => {
        setModalType(MODAL_TYPES.HIDDEN);
    }

    //  TODO: this is for testing  only. Delete it in production.
    window.testSetAllSteps = (newIndex = sectionIndex, toChecked = true) => {
        setAllSteps(newIndex, sections, toChecked);
    }

    //  TODO: this is for testing  only. Delete it in production.
    window.testSetAllTools = (boolValue, newIndex = sectionIndex) => {
        //  We don't want to mutate state directly, so we map()
        const updatedSections = sections.map((section, index) => {
            const mustHaveTools = section.mustHaveTools.map((mustHaveTool) => {
                return index === newIndex
                    ? {...mustHaveTool, checked: boolValue}
                    : {...mustHaveTool}
            });
            const mayNeedTools = section.mayNeedTools.map((mayNeedTool) => {
                return index === newIndex
                    ? {...mayNeedTool, checked: boolValue}
                    : {...mayNeedTool}
            });
            return {...section, mustHaveTools, mayNeedTools};
        });

        setSections(updatedSections);
        storeSectionData(updatedSections);
    }


    /* ****************************************************************
     *      Rendering
    **************************************************************** */

    const className = `App${menuVisible ? " menu-visible" : "" }`;

    let lastCompletedSection = sections.reduce((accumulator, currentSection, index) => {
        return currentSection.completed && (index === accumulator + 1)
            ? index
            : accumulator;
    }, -1);
    lastCompletedSection = Math.min(lastCompletedSection + 1, APP_DATA.sections.length - 1);

    const labels = sections.map((section) => {
        return {labelKey: section.labelKey, completed: section.completed};
    });

    const mainScreen = (screenIndex === LANDING_SCREEN_INDEX)
        ? ( <Landing
                hirePro={() => showModal(MODAL_TYPES.HIRE_PRO)}
                continue={() => navigateToScreen(COMPATIBILITY_SCREEN_INDEX)}
            />
        )
        : (screenIndex === COMPATIBILITY_SCREEN_INDEX)
            ? ( <div className="main-screen compatibility-checklist">
                    <SectionScreen
                        isBranching={true}
                        section={compatibility}
                        launchInfo={(step) => launchInfo(step)}
                        toggleCheck={(step) => {toggleCheck(step, true)}}
                        selectOption={selectOption}
                        selectNextSection={() => navigateToScreen(TOOLS_SCREEN_INDEX)}
                        startOver={clearAllSteps}
                    />
                </div>
            )
            : (screenIndex === TOOLS_SCREEN_INDEX)
                ?(
                    <div className="main-screen tool-list-container">
                        <ToolList
                            toggleCheck={toggleTool}
                            mustHaveTools={tools.mustHaveTools}
                            mayNeedTools={tools.mayNeedTools}
                            continue={() => navigateToScreen(OVERVIEW_SCREEN_INDEX)}
                            canClose={true}
                        />
                    </div>
                )
                : (screenIndex === OVERVIEW_SCREEN_INDEX)
                    ? (<Overview
                        labels={labels}
                        showStartOverButton={ConfigurationManager.userCanAdvance()}
                        lastCompletedSection={lastCompletedSection}
                        continue={() => {
                            navigateToSection(lastCompletedSection);
                        }}
                        startOver={() => {showModal(MODAL_TYPES.START_OVER)}}
                    />)
                    : ( <div className="main-screen">
                            <SectionScreen
                                isBranching={false}
                                section={sections[sectionIndex]}
                                numSections={sections.length}
                                sectionIndex={sectionIndex}
                                launchInfo={(step) => launchInfo(step)}
                                toggleCheck={toggleCheck}
                                selectOption={selectOption}
                                selectNextSection={selectNextSection}
                                atLastSection={atLastSection()} // invocation
                            />
                        </div>
                    );

    const expandedInfo = isMobile()
        ? (
            <ExpandedInfo
                step={expandedStep}
                closeInfo={closeInfo}
                visible={infoVisible}
                canClose={true}
            />
        )
        : null;

    const modalActionYes = () => {
        if (modalType === MODAL_TYPES.START_OVER) {
            clearAllSteps();
        } else if (modalType === MODAL_TYPES.HIRE_PRO) {
            goToLink(LINKS.HIRE_PRO_HAS_DOORBELL);
        }
    }

    const modalActionNo = () => {
        if (modalType === MODAL_TYPES.START_OVER) {
            closeModal();
        } else if (modalType === MODAL_TYPES.HIRE_PRO) {
            goToLink(LINKS.HIRE_PRO_NO_DOORBELL);
        }
    }

    const dom = isLandscape()
        ? (
            <div className="orientation-warning"><p><Trans i18nKey="intro.portraitOnly" /></p></div>
        )
        : (<div className="app-holder">
                <Header
                    visible={screenIndex > LANDING_SCREEN_INDEX}
                    launchMenu={launchMenu}
                />
                {mainScreen}
                <div className="overlay-holder">
                    <Menu
                        labels={labels}
                        selectSection={index => navigateToSection(index)}
                        closeMenu={closeMenu}
                        showCompatibility={() => navigateToScreen(COMPATIBILITY_SCREEN_INDEX, false)}
                        compatibilityCompleted={ConfigurationManager.userCanAdvance()}
                        showTools={() => navigateToScreen(TOOLS_SCREEN_INDEX, false)}
                        toolsCompleted={allToolsAreChecked()}
                        changeLanguage={(newLang) => i18n.changeLanguage(newLang)} //  TODO: delete this
                        visible={menuVisible}
                        hirePro={() => showModal(MODAL_TYPES.HIRE_PRO)}
                    />
                    {expandedInfo}
                </div>
                <Modal
                    closeModal={closeModal}
                    modalType={modalType}
                    modalActionYes={modalActionYes}
                    modalActionNo={modalActionNo}
                />
            </div>
        );

    return (<div className={className}>
            {dom}
    </div>);
}

export default App;
