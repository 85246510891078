//  Pseudo-enum for the screen order
export const LANDING_SCREEN_INDEX = 0;
export const COMPATIBILITY_SCREEN_INDEX = 1;
export const TOOLS_SCREEN_INDEX = 2;
export const OVERVIEW_SCREEN_INDEX = 3;
export const SECTION_SCREEN_INDEX = 4;

//  Local storage
export const SECTION_STORAGE_KEY = 'sections';
export const COMPATIBILITY_STORAGE_KEY = 'compatibility';
export const TOOL_LIST_STORAGE_KEY = 'toolList';
export const VERSION_STORAGE_KEY = 'version';

//  CSS breakpoints
export const MOBILE_PORTRAIT = 'MOBILE_PORTRAIT';
export const MOBILE_LANDSCAPE = 'MOBILE_LANDSCAPE';
export const TABLET_PORTRAIT = 'TABLET_PORTRAIT';
export const TABLET_LANDSCAPE = 'TABLET_LANDSCAPE';
export const DESKTOP = 'DESKTOP';
export const PORTRAIT = 'PORTRAIT';
export const LANDSCAPE = 'LANDSCAPE';

export const MEDIA_QUERIES = {
    [MOBILE_PORTRAIT]: '(max-device-width: 767px) and (orientation: portrait)',
    [MOBILE_LANDSCAPE]: '(max-device-height: 767px) and (orientation: landscape)',
    [TABLET_PORTRAIT]: '(min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait)',
    [TABLET_LANDSCAPE]: '(min-device-height : 768px) and (max-device-height : 1024px) and (max-device-width : 1024px) and (orientation: landscape)',
    [DESKTOP]: '(min-device-width : 1024px) and (min-device-height : 1024px)',
    [PORTRAIT]: '(orientation: portrait)',
    [LANDSCAPE]: '(orientation: landscape)'
};

//  Route URLs
export const URL = {
    LANDING: '/',
    COMPATIBILITY: '#/electrical-compatibility',
    INSTALL_ITEMS: '#/install-items',
    OVERVIEW: '#/overview',
    PAIRING: '#/pairing',
    TEST_WIFI: '#/test-wifi',
    REMOVE_OLD_DB: '#/remove-old',
    INSTALL_MOUNT: '#/install-mount',
    INSTALL_CHIME: '#/install-chime',
    FINISH: '#/finish',
};
export const TITLE_KEYS = {
    [URL.LANDING]: '',
    [URL.COMPATIBILITY]: 'compatibilityCheck.mainLabel',
    [URL.INSTALL_ITEMS]: 'intro.toolsChecklist',
    [URL.OVERVIEW]: 'menu.installationGuide',
    [URL.PAIRING]: 'pairing.mainLabel',
    [URL.TEST_WIFI]: 'testWifiAndVideo.mainLabel',
    [URL.REMOVE_OLD_DB]: 'removeDoorbellAndGetReady.mainLabel',
    [URL.INSTALL_MOUNT]: 'installMount.mainLabel',
    [URL.INSTALL_CHIME]: 'installChime.mainLabel',
    [URL.FINISH]: 'finish.mainLabel',
};

//  External links
export const LINKS = {
    SUPPORT: 'https://www.logi.com/circleviewdoorbell/support',
    SUBMIT_TO_SUPPORT: 'https://support.logi.com/hc/requests/new?ticket_form_id=360000621393&pID=76980599-3960-11eb-993d-e5aa90761c1a',
    BUY_PRODUCT: 'https://www.logi.com/circleviewdoorbell',
    HIRE_PRO_HAS_DOORBELL: 'https://www.logi.com/circleviewdoorbell/hirepro',
    HIRE_PRO_NO_DOORBELL: 'https://www.logi.com/circleviewdoorbell',
    // TODO: Pull out this link to here (in compatibility step) APPLE_HOMEKIT: 'https://www.apple.com/ios/home/',
};

export const SECTION_FOR_URL = [
    URL.PAIRING, URL.TEST_WIFI, URL.REMOVE_OLD_DB, URL.INSTALL_MOUNT, URL.INSTALL_CHIME, URL.FINISH
];

export const SCREEN_FOR_URL = [
    URL.LANDING, URL.COMPATIBILITY, URL.INSTALL_ITEMS, URL.OVERVIEW
];

//  Branching logic
export const ALL_WIRES = 'all';
export const SECTION_TYPES = {
    NORMAL: 0,
    BRANCHING: 1,
    RESULTS: 2
}
export const WIRE_TYPES = {
    TRANS_WIRE: 0,
    FRONT_WIRE: 1,
    THIRD_WIRE: 2,
    BACK_WIRE: 3
}
export const CHOICE_TYPES = {
    TYPE_STANDARD: 'standard',
    TYPE_INTERCOM: 'intercom',
    TYPE_UNKNOWN: 'unknown',
    TYPE_HAS_WIRES: 'hasWires',
    TYPE_NO_WIRES: 'noWires',
    TYPE_NO_OPTIONS: 'noOptions',
    TYPE_1: 1,
    TYPE_2: 2,
    TYPE_3: 3,
    TYPE_ANALOG: 'analog',
    TYPE_BUTTON_CONFIG_CONTACT_SUPPORT: 'contactSupport',
    TYPE_BUTTON_CONFIG_FAIL: 'fail',
    TYPE_BUTTON_CONFIG_HIRE_PRO: 'hirePro',
    TYPE_BUTTON_CONFIG_SUCCESS_OWNS_DOORBELL: 'successOwnsDoorbell',
    TYPE_BUTTON_CONFIG_SUCCESS_BUY_DOORBELL: 'successBuyDoorbell',
    TYPE_DIGITAL: 'digital',
    TYPE_IS_IQ_AMERICA: 'isIqAmericaChime',
    TYPE_NOT_IQ_AMERICA: 'notIqAmericaChime',
    TYPE_BATTERY: 'battery',
    TYPE_NO_BATTERY: 'noBattery',
    TYPE_NO_TRANSFORMER_WIRE: 'noTransformerWire',
    TYPE_NO_FRONT_WIRE: 'noFrontWire',
    TYPE_NO_KNOWN_WIRE: 'noKnownWire',
    TYPE_NO_MINUS_WIRE: 'noMinusWire',
    TYPE_NO_TR_AC_WIRE: 'noTrAcWire',
    TYPE_HAS_BUTTON_PLUS_WIRE: 'hasButtonPlusWire',
    TYPE_NO_BUTTON_PLUS_WIRE: 'noButtonPlusWire',
    TYPE_HAS_UNLABELED_WIRE: 'hasUnlabeledWire',
    TYPE_NO_UNLABELED_WIRE: 'noUnlabeledWire',
    TYPE_HAS_BACK_WIRE: 'hasBackWire',
    TYPE_NO_BACK_WIRE: 'noBackWire',
    TYPE_HAS_ADDITIONAL_WIRE: 'hasAdditionalWire',
    TYPE_NO_ADDITIONAL_WIRE: 'noAdditionalWire',
    TYPE_HAS_DOORBELL: 'hasDoorbell',
    TYPE_NO_DOORBELL: 'noDoorbell',
    TYPE_POWER_HIGH: 'vaHigh',
    TYPE_POWER_LOW: 'vaLow',
    TYPE_POWER_NO_RATING: 'vaNoRating',
    TYPE_POWER_NO_TRANSFORMER: 'vaNoTransformer',
    TYPE_VOLTAGE_MEDIUM: 'vMedium',
    TYPE_VOLTAGE_LOW: 'vLow',
    TYPE_VOLTAGE_HIGH: 'vHigh',
    TYPE_VOLTAGE_NO_RATING: 'vNoRating',
}

//  Modal popups
export const MODAL_TYPES = {
    HIDDEN: 0,
    START_OVER: 1,
    HIRE_PRO: 2
}
