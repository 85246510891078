
//  Tools
import anchor from '../assets/productImages/anchor.png';
import commandStrip from '../assets/productImages/commandStrip.png';
import doorChime from '../assets/productImages/doorChime.png';
import drill from '../assets/productImages/drill.png';
import iPhone from '../assets/productImages/iPhone.png';
import level from '../assets/productImages/level.png';
import microUSB from '../assets/productImages/microUSB.png';
import mount from '../assets/productImages/mount.png';
import paint from '../assets/productImages/paint.png';
import papaya from '../assets/productImages/papaya.png';
import pryTool from '../assets/productImages/pryTool.png';
import router from '../assets/productImages/router.png';
import sandpaper from '../assets/productImages/sandpaper.png';
import screwdriver from '../assets/productImages/screwdriver.png';
import screws from '../assets/productImages/screws.png';
import sizingTemplate from '../assets/productImages/sizingTemplate.png';
import spackle from '../assets/productImages/spackle.png';
import usbCharger from '../assets/productImages/usbCharger.png';
import wireLabel from '../assets/productImages/wireLabel.png'
import wireNuts from '../assets/productImages/wireNuts.png';
import wires from '../assets/productImages/wires.png';
import wireStripper from '../assets/productImages/wireStripper.png';

//  Illustrations
import imgComp_a2_ringDoorbell from '../assets/illustrations/A-2-Ring-Doorbell.svg';
import imgComp_a4_confirmWiredChime from '../assets/illustrations/A4-Confirm-Wired-Chime.png';
import imgComp_O1_power from '../assets/illustrations/O-1-Confirm-Transformer-Power-Rating.svg';
import imgComp_O2_voltage from '../assets/illustrations/O-2-Confirm-Transformer-Voltage-Rating.svg';
import img01_01_15Feet from '../assets/illustrations/1_Connect-Phone-to-Wifi-a.svg';
import img01_01_wifi from '../assets/illustrations/1_Connect-Phone-to-Wifi-b.svg';
import img01_02_power from '../assets/illustrations/1_Plug-in-to-power.svg';
import img01_03_aboutToTap from '../assets/illustrations/1_Pair-a.svg';
import img01_03_vibrate from '../assets/illustrations/1-Pair-b.svg';
import img01_03_qrLarge from '../assets/illustrations/1_Pair-QR-Code-Large.svg';
import img01_03_qrSmall from '../assets/illustrations/1_Pair-QR-Code-Small.svg';
import img02_01_doorClose from '../assets/illustrations/2-Go-Outside.svg';
import img02_02_pluggedIn from '../assets/illustrations/2-Plug-in-to-Power.svg';
import img02_03_homeApp from '../assets/illustrations/2_Open-Home-app.svg';
import img02_04_door from '../assets/illustrations/2_Ensure-optimal-wifi-performance.svg';
import img02_05_cameraMove from '../assets/illustrations/2_Find-Optimal-Video-POV.svg';
import img03_01_orient from '../assets/illustrations/3_Orient-Template.svg';
import img03_01_straight from '../assets/illustrations/3_Orient-Template-Straight.svg';
import img03_01_right from '../assets/illustrations/3_Orient-Template-Right.svg';
import img03_01_left from '../assets/illustrations/3_Orient-Template-Left.svg';
import img03_02_size from '../assets/illustrations/3_Pre-Check-Doorbell-Size.svg';
import img03_02_straight from '../assets/illustrations/3_Pre-Check-Doorbell-Size-Straight.svg';
import img03_02_right from '../assets/illustrations/3_Pre-Check Doorbell Size-Right.svg';
import img03_02_left from '../assets/illustrations/3_Pre-Check-Doorbell-Size-Left.svg';
import img03_03_power from '../assets/illustrations/3_Turn-Off-House-Power.svg';
import img03_06_lineUp from '../assets/illustrations/3_Line-Up-Template.svg';
import img03_07_screws from '../assets/illustrations/3_Prepare-Screw-Holes-Confirm-Sizing.svg';
import img03_08_recess from '../assets/illustrations/3_Ensure-Correct-Wall-Recess-Size-Good.svg';
import img03_08_recessTooLarge from '../assets/illustrations/3_Ensure-Correct-Wall-Recess-Size-TooLarge.svg';
import img03_08_recessTooSmall from '../assets/illustrations/3_Ensure-Correct-Wall-Recess-Size-TooSmall.svg';
import img03_09_wire from '../assets/illustrations/3_Ensure-Wires-Length.svg';
import img04_01_insert from '../assets/illustrations/4_Connect-Wires.svg';
import img04_03_snap from '../assets/illustrations/4_Snap-into-Mount.svg';
import img05_02_analog from '../assets/illustrations/5_Identify-Chime-Type-Analog.svg';
import img05_02_digital from '../assets/illustrations/5_Identify-Chime-Type-Digital.svg';
import img05_03_locate from '../assets/illustrations/5_Locate-Wire-Terminal.svg';
import img06_00_diagram from '../assets/illustrations/5_ChimeKit-Diagram.svg';
import img06_00_diagramNoBack from '../assets/illustrations/5_ChimeKit-Diagram-No-Back.svg';
import img06_00_wiringSetup from '../assets/illustrations/5_ChimeKit-Wiring-Setup.svg';
import img06_01_cableOut from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-WireOut.svg';
import img06_01_cableInConnector from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-WiresInConnector.svg';
import img06_01_cableBackOut from '../assets/illustrations/6_Prepare-Back-Cable-Connector.svg';
import img06_01_cableBackInConnector from '../assets/illustrations/6_Prepare-Back-Cable-WiresInConnector.svg';
import img06_01_label1 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-ConnectorLabel1.svg';
import img06_01_label2 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-ConnectorLabel2.svg';
import img06_01_label3 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-ConnectorLabel3.svg';
import img06_01_label4 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-Label4.svg';
import img06_01_label5 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-Label5.svg';
import img06_01_label6 from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-Label6.svg';
import img06_01_cableIn from '../assets/illustrations/6_Prepare-Existing-Transformer-Cable-WireIn.svg';
import img06_05_cableRouted from '../assets/illustrations/6_Determine-Chime-Kit-Placement-and-Route-Wires.svg';
import img06_06_removeCover from '../assets/illustrations/6_Connect-Wires-to-Chime-Kit-1.svg';
import img06_06_cable3 from '../assets/illustrations/6_Connect-Wires-to-Chime-Kit-2.svg';
import img07_02_chimeSwitch from '../assets/illustrations/7_Chime-Switch.svg';
import img07_02_chimeSwitchAnalog from '../assets/illustrations/7_Chime-Switch-Analog.svg';
import img07_02_chimeSwitchDigital from '../assets/illustrations/7_Chime-Switch-Digital.svg';
import img07_03_cover from '../assets/illustrations/6_Connect-Wires-to-Chime-Kit-3.svg';
import img07_04_commandStrip from '../assets/illustrations/7_Command-Strip.svg';
import img07_04_finishedRender from '../assets/illustrations/7_Finished_Render.png';
import img07_05_correctBlue from '../assets/illustrations/7_Confirm-Powered-Correctly-Blue.svg';
import img07_05_correctRed from '../assets/illustrations/7_Confirm-Powered-Correctly-Red.svg';

const imageHash = {
    //  Must-have/may-need tools
    "anchor": anchor,
    "commandStrip": commandStrip,
    "doorChime": doorChime,
    "drill": drill,
    "iPhone": iPhone,
    "level": level,
    "microUSB": microUSB,
    "mount": mount,
    "paint": paint,
    "papaya": papaya,
    "pryTool": pryTool,
    "router": router,
    "sandpaper": sandpaper,
    "screwdriver": screwdriver,
    "screws": screws,
    "sizingTemplate": sizingTemplate,
    "spackle": spackle,
    "usbCharger": usbCharger,
    "wireLabel": wireLabel,
    "wireNuts": wireNuts,
    "wires": wires,
    "wireStripper": wireStripper,

    //  Inline images
    "imgComp_a2_ringDoorbell": imgComp_a2_ringDoorbell,
    "imgComp_a4_confirmWiredChime": imgComp_a4_confirmWiredChime,
    "imgComp_O1_power": imgComp_O1_power,
    "imgComp_O2_voltage": imgComp_O2_voltage,
    "img01_01_15Feet": img01_01_15Feet,
    "img01_01_wifi": img01_01_wifi,
    "img01_02_power": img01_02_power,
    "img01_03_aboutToTap": img01_03_aboutToTap,
    "img01_03_vibrate": img01_03_vibrate,
    "img01_03_qrLarge": img01_03_qrLarge,
    "img01_03_qrSmall": img01_03_qrSmall,
    "img02_01_doorClose": img02_01_doorClose,
    "img02_02_pluggedIn": img02_02_pluggedIn,
    "img02_03_homeApp": img02_03_homeApp,
    "img02_04_door": img02_04_door,
    "img02_05_cameraMove": img02_05_cameraMove,
    "img03_01_orient": img03_01_orient,
    "img03_01_straight": img03_01_straight,
    "img03_01_right": img03_01_right,
    "img03_01_left": img03_01_left,
    "img03_02_size": img03_02_size,
    "img03_02_straight": img03_02_straight,
    "img03_02_right": img03_02_right,
    "img03_02_left": img03_02_left,
    "img03_03_power": img03_03_power,
    "img03_06_lineUp": img03_06_lineUp,
    "img03_07_screws": img03_07_screws,
    "img03_08_recess": img03_08_recess,
    "img03_08_recessTooLarge": img03_08_recessTooLarge,
    "img03_08_recessTooSmall": img03_08_recessTooSmall,
    "img03_09_wire": img03_09_wire,
    "img04_01_insert": img04_01_insert,
    "img04_03_snap": img04_03_snap,
    "img05_02_analog": img05_02_analog,
    "img05_02_digital": img05_02_digital,
    "img05_03_locate": img05_03_locate,
    "img06_00_diagram": img06_00_diagram,
    "img06_00_diagramNoBack": img06_00_diagramNoBack,
    "img06_00_wiringSetup": img06_00_wiringSetup,
    "img06_01_cableOut": img06_01_cableOut,
    "img06_01_cableInConnector": img06_01_cableInConnector,
    "img06_01_cableBackOut": img06_01_cableBackOut,
    "img06_01_cableBackInConnector": img06_01_cableBackInConnector,
    "img06_01_label1": img06_01_label1,
    "img06_01_label2": img06_01_label2,
    "img06_01_label3": img06_01_label3,
    "img06_01_label4": img06_01_label4,
    "img06_01_label5": img06_01_label5,
    "img06_01_label6": img06_01_label6,
    "img06_01_cableIn": img06_01_cableIn,
    "img06_05_cableRouted": img06_05_cableRouted,
    "img06_06_removeCover": img06_06_removeCover,
    "img06_06_cable3": img06_06_cable3,
    "img07_02_chimeSwitch": img07_02_chimeSwitch,
    "img07_02_chimeSwitchAnalog": img07_02_chimeSwitchAnalog,
    "img07_02_chimeSwitchDigital": img07_02_chimeSwitchDigital,
    "img07_03_cover": img07_03_cover,
    "img07_04_commandStrip": img07_04_commandStrip,
    "img07_04_finishedRender": img07_04_finishedRender,
    "img07_05_correctBlue": img07_05_correctBlue,
    "img07_05_correctRed": img07_05_correctRed
};

export default imageHash;
