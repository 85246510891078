import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

//  Components
import Tools from './Tools';

//  Other services
import ConfigurationManager from '../services/configuration';
import { isMobile } from '../services/breakpoints';
import {goToLink} from "../utilities/statics";
import {LINKS} from "../data/constants";

const ToolList = (props) => {

    const {hasDoorbell} = ConfigurationManager.getHardwareConfiguration();
    const hireLink = hasDoorbell ? LINKS.HIRE_PRO_HAS_DOORBELL : LINKS.HIRE_PRO_NO_DOORBELL;

    if (isMobile()) {
        return (
            <div className="section-container">
                <header>
                    <p className="section-title">
                        <Trans i18nKey="intro.toolsChecklist" />
                    </p>
                </header>
                <main>
                    <Tools {...props} />
                    <div className="tool-list-btn-container">
                        <button
                            className="btn btn-continue"
                            onClick={props.continue}
                        >
                            <Trans i18nKey="intro.continue" />
                        </button>
                        <button
                            className="btn btn-start-over"
                            onClick={() => goToLink(hireLink)}
                        >
                            <Trans i18nKey="menu.hirePro" />
                        </button>
                    </div>
                </main>
            </div>
        );
    }
    return (
        <Fragment>
            <header>
                <p className="section-title">
                    <Trans i18nKey="intro.toolsChecklist" />
                </p>
            </header>
            <main>
                <div className="column-left">
                    {/* desktop only -- contains an image */}
                </div>
                <div className="column-right">
                    <Tools {...props} />
                    <div className="button-container">
                        <button
                            className="btn btn-start-over"
                            onClick={() => goToLink(hireLink)}
                        >
                            <Trans i18nKey="menu.hirePro" />
                        </button>
                        <button
                            className="btn btn-continue"
                            onClick={props.continue}
                        >
                            <Trans i18nKey="intro.continue" />
                        </button>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default ToolList;
