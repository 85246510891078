import imageHash from '../data/links';

/**
 * Aggregates the output of the callback on key-value object as a new Array.
 *
 * @param {Object} obj - The object whose keys will be iterated over.
 * @param {function} callback - The function called on each iterated value.
 * @returns {Array}
 */
export const mapReduce = (obj, callback) => {
    const result = [];
    Object.keys(obj).forEach((key) => {
        result.push(callback.call(obj, obj[key], key, obj));
    });
    return result;
};


/**
 * Creates a map from an array of objects according to their id.
 *
 * @param {Object} array - The array whose objects will be iterated over.
 * @param {string} key - The object's key to use for the map key. Defaults to "id".
 * @returns {Object}
 */
export const mapArray = (array, key = 'id') => {
    const result = {};
    array.forEach((obj) => {
        result[obj[key]] = Object.assign({}, obj);
    });
    return result;
};

/**
 * Creates an array from an object. Uses a key if provided; otherwise, the
 * object should only have a single level.
 *
 * @param {Object} obj - The object whose keys will be iterated over.
 * @param {string} key - If we only want to capture one level of a multi-level object, use the key to extract the right field.
 * @returns {Array}
 */
export const obj2Array = (obj) => {
    return Object.keys(obj).map((key) => obj[key])
};


/**
 * Checks if the parameter is exists or not. Returns the value that you would expect from python or ruby, not
 * javascript.
 *
 *  * {@code
 *      exists({}); // false
 *      exists([]); // false
 *      exists(''); // false
 *      exists(false); // false
 *      exists(null); // false
 *      exists(undefined); // false
 *      exists({key: 'value'}); // true
 *      exists(true); // true
 *      ...
 * }
 *
 * @param {Object|Array|string|undefined|...} x
 * @return {Boolean}
 */
export const exists = (x) => {
    if (undefined === x) {
        return false;
    }
    else if (null === x) {
        return false;
    }
    else if ('number' === typeof x) {
        return true;
    }
    else if ('string' === typeof x) {
        return Boolean(x);
    }
    else if ('boolean' === typeof x) {
        return true;
    }
    else if (x instanceof HTMLElement) {
        return true;
    }
    else if ('object' === typeof x) {
        return 0 !== Object.keys(x).length;
    }
    else if ('function' === typeof x) {
        return true;
    }
    return false;
};

/**
 * Accepts a variable number of objects and merges them into the each other in the order that they are given.
 */
export const merge = (...args) => {
    let result = {};
    for (let i = 0; i < args.length; i++) {
        result = Object.assign(result, args[i]);
    }
    return result;
};


/**
 * Conditionally concatenate two strings together. If the condition is true, the appendedTrue is
 * appended to the base. If the condition is false, the appendedFalse is appended. If the appendedFalse
 * parameter is omitted and the condition is false, the original string is returned.
 *
 * @param {Boolean} condition - The condition to be evaluated.
 * @param {string} base - The original string.
 * @param {string} appendedTrue - The concatenation, if the condition is true.
 * @param {string} appendedFalse - An optional concatenation, if the condition is false.
 */
export const ternaryConcat = (condition, base, appendedTrue, appendedFalse = '') => ((condition)
    ? base + appendedTrue
    : base + appendedFalse);


/**
 * Checks if A contains B.
 *
 * @param {string|Array} a - The full string/Array.
 * @param {string|Array} b - The substring/string.
 * @return {Boolean}
 */
export const contains = (a, b) => (a.indexOf(b) > -1);


/**
 * Checks if object X and Y are equal.
 *
 * @param {object} x
 * @param {object} y
 * @return {Boolean}
 */
export const objectEquals = (x, y) => {
    if (null === x || x === undefined || null === y || y === undefined) {
        return x === y;
    }

    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }

    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) {
        return x === y;
    }

    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) {
        return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) {
        return false;
    }
    if (!(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    const p = Object.keys(x);
    return Object.keys(y).every(i => p.indexOf(i) !== -1) &&
        p.every(i => objectEquals(x[i], y[i]));
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = item => (item && 'object' === typeof item && !Array.isArray(item));


/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
    return mergeDeep(target, ...sources);
};


/**
 * Determines if a DOM node is ultimately a child of another node. Returns false if the node *is*
 * the other node.
 * @param {HTMLElement} child - The node in question.
 * @param {HTMLElement} ancestor - The potential parent/ancestor node.
 * @return {boolean}
 */
export const isDescendant = (child, ancestor) => {
    if (child === ancestor) {
        return false;
    }
    while (child !== document.body) {
        if (child === ancestor) {
            return true;
        }
        child = child.parentElement;
    }
    return false;
};

/**
 * Determines if the operating system CEF is running on is OSX
 */
export const isMac = function () {
    return (('MacIntel') === window.navigator.platform);
};

/**
 * Determines if the operating system CEF is running on is Windows
 */
export const isWindows = function () {
    return (('Win32') === window.navigator.platform);
};


/**
 * Finds the ancestor that is scrollable
 * TODO: this is a hack; we just assume '.app' is that ancestor. Is this robust enough?
 * @param {HTMLElement} node - The descendant node.
 * @return {HTMLElement}
 */

export const getScrollDiv = (node) => {
    return node.closest('.App');
}


/**
 * Returns an image from a key
 * @param {String} key - The key for the image hash.
 * @return {Image}
 */
export const getImageSource = (key) => {
    const newSrc = imageHash[key];
    return exists(newSrc) ? newSrc : null;
};


/**
 * Replaces images in an HTML node
 * This function is destructive; the node must be mutable.
 * @param {HTMLElement} node - The node in question. It gets mutated.
 * @return none
 */
export const fixImageSource = (node) => {
    const images = Array.from(node.querySelectorAll("img"));
    images.forEach((img) => {
        const newSrc = imageHash[img.src.match(/[^/]+$/)[0]];
        if (exists(newSrc)) {
            img.src = newSrc;
        }
    });
};

/**
 * Removes images in an HTML node
 * This function is destructive; the node must be mutable.
 * @param {HTMLElement} node - The node in question. It gets mutated.
 * @return none
 */
export const removeImages = (node) => {
    const images = Array.from(node.querySelectorAll(".img-holder"));
    if (images.length > 0) {
        const parent = images[0].parentNode;
        images.forEach((img) => {
            img.parentNode.removeChild(img);
        });

        //  The NodeList can get fragmented, which leads to a drawing bug in Android.
        //  Concatenate the content into a single node
        const remainingChildren = Array.from(parent.childNodes);
        let html = '';
        while (remainingChildren.length > 1) {
            const lastChild = remainingChildren.pop();
            html = `${lastChild.textContent} ${html}`;
            lastChild.parentNode.removeChild(lastChild);
        }
        if (exists(html)) {
            remainingChildren[0].textContent += html;
        }
    }
};

/**
 * Determines if there's something after an image node, or if the first image is the last element.
 * @param {HTMLElement} node - The node in question.
 * @return {Bool} - true if there's an image and then something after it; false if not
 */
export const hasPostImageElement = (node) => {
    const images = Array.from(node.querySelectorAll(".img-holder"));
    if (images.length === 0) {
        return false; // There are no images, so there's no post-image text
    } else if (images.length > 1) {
        return true; // There are two images, so there's an element after the first image
    }
    //  There's a single image. Determine if anything trails it.
    return images[0] !== node.lastChild;
};

/**
 * Takes an SVG string, and converts it to a displayable image.
 * @param {String} svg - An editable string, representing an SVG.
 * @return {String} - A data:image.
 */
export const serializeSvg = (svg) => {
    const cleaned = svg
        .replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
        .replace(/\s\s+/g, ' ') // Condense multiple spaces
        .replace(/'/gim, '\\i'); // Normalize quotes

    const encoded = encodeURIComponent(cleaned)
        .replace(/\(/g, '%28') // Encode brackets
        .replace(/\)/g, '%29');

    return `data:image/svg+xml;charset=UTF-8,${encoded}`;
}

/**
 * Takes an integer, and creates an SVG with that number inside a circle.
 * @param {Number} num - An integer.
 * @return {String} - A data:image.
 */
export const generateNumberedCheckbox = (num) => {
    const getCheckboxTemplate = num => `<svg width="25px" height="25px" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <title>check - off</title>
    <g id="Symbols" stroke="#000000F0" stroke-width="1.4" fill="none" fill-rule="evenodd" opacity="0.3">
        <text id="#" font-family="BrownPro-Regular, BrownPro" stroke-width="1.0" font-size="9" font-weight="normal" line-spacing="20" width="25px" text-anchor="middle" letter-spacing="0.5" fill="#2F3132"><tspan x="12.5" y="15.5">${num}</tspan></text>
        <circle id="Oval" fill-rule="nonzero" cx="12.5" cy="12.5" r="11.5"></circle>
    </g>
</svg>`;

    const svg = getCheckboxTemplate(num);
    return serializeSvg(svg);
}

/**
 * Navigates to a URL.
 * @param {string} link - An HTTP/S link.
 * @return {none}
 */
export const goToLink = (link) => {
    window.location = link;
};
