import React, { useState } from 'react';
import { Trans } from 'react-i18next';

//  Components
import StepSelect from './StepSelect';
import TranslatedListContent from './TranslatedListContent';
import StepHeader from './StepHeader';

//  Other
import {
    exists,
    getImageSource,
} from '../utilities/statics';
import ConfigurationManager from '../services/configuration';


const StepMobile = (props) => {
    const hasOptions = exists(props.step) && ConfigurationManager.hasOptions(props.step);

    const [isTruncated, setIsTruncated] = useState(false); // Whether we need to truncate

    const { key: contentKey, image } = props.step; // Rename 'key' for clarity

    const imageDiv = image !== undefined
        ? (
            <div className="img-holder">
                <img
                    src={getImageSource(image)}
                    className="illustration"
                    alt="illustration"
                />
            </div>
        )
        : null;

    const btnMore = isTruncated
        ? (<button
            className="btn btn-more"
            onClick={() => props.launchInfo()}
        >
            <Trans i18nKey="menu.moreDetail" />
        </button>)
        : null;

    const contentClass = props.isBranching
        ? 'content' // Don't truncate if the parent is CompatibilityCheckMobile
        : `content ${isTruncated ? "truncate3" : "truncate5"}`;

    const mainDiv = hasOptions
        ? (
            <main className="select-main">
                <TranslatedListContent
                    {...props}
                    contentClass={contentClass}
                    contentKey={contentKey}
                    setIsTruncated={(isTruncated) => setIsTruncated(isTruncated)} // Truncate if longer than 5 lines
                />
                <StepSelect {...props} />
            </main>
        )
        : contentKey.length > 0
            ? (
                <main>
                    <TranslatedListContent
                        {...props}
                        contentClass={contentClass}
                        contentKey={contentKey}
                        setIsTruncated={setIsTruncated} // Truncate if longer than 5 lines
                    />
                    { imageDiv }
                    { btnMore }
                </main>
            )
            : null;

    return <StepHeader {...props} children={mainDiv} launchInfo={null} />
};

export default StepMobile;
