// module should be loaded after 'ready' event for correct language detection

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import DANISH from '../locales/da.json';
import GERMAN from '../locales/de.json';
import GREEK from '../locales/el.json';
import ENGLISH from '../locales/en.json';
import SPANISH from '../locales/es.json';
import FINNISH from '../locales/fi.json';
import FRENCH from '../locales/fr.json';
import ITALIAN from '../locales/it.json';
import JAPANESE from '../locales/ja.json';
import KOREAN from '../locales/ko.json';
import NORWEGIAN from '../locales/nb.json';
import DUTCH from '../locales/nl.json';
import POLISH from '../locales/pl.json';
import PORTUGUESE from '../locales/pt.json';
import RUSSIAN from '../locales/ru.json';
import SWEDISH from '../locales/sv.json';
import TURKISH from '../locales/tr.json';
import CHINESE_SIMPLIFIED from '../locales/zh-CN.json';
import CHINESE_TRADITIONAL from '../locales/zh-TW.json';

export const I18N_NAMESPACE = 'translation';

export const LANGUAGE_CODES = {
    DANISH: 'da',
    GERMAN: 'de',
    GREEK: 'el',
    ENGLISH: 'en',
    SPANISH: 'es',
    FINNISH: 'fi',
    FRENCH: 'fr',
    ITALIAN: 'it',
    JAPANESE: 'ja',
    KOREAN: 'ko',
    NORWEGIAN_BOKML: 'nb',
    NORWEGIAN_NYNORSK: 'nn',
    DUTCH: 'nl',
    POLISH: 'pl',
    PORTUGUESE: 'pt',
    RUSSIAN: 'ru',
    SWEDISH: 'sv',
    TURKISH: 'tr',
    CHINESE: 'zh',
    CHINESE_SIMPLIFIED: 'zh-CN',
    CHINESE_TRADITIONAL: 'zh-TW',
};

export const ASIAN_LANGUAGES = [
    LANGUAGE_CODES.GREEK,
    LANGUAGE_CODES.JAPANESE,
    LANGUAGE_CODES.KOREAN,
    LANGUAGE_CODES.RUSSIAN,
    LANGUAGE_CODES.CHINESE_SIMPLIFIED,
    LANGUAGE_CODES.CHINESE_TRADITIONAL,
];

// if adding other non latin languages please update the `ASIAN_LANGUAGES` variable above
export const RESOURCES = {
    [LANGUAGE_CODES.DANISH]: { [I18N_NAMESPACE]: DANISH },
    [LANGUAGE_CODES.GERMAN]: { [I18N_NAMESPACE]: GERMAN },
    [LANGUAGE_CODES.GREEK]: { [I18N_NAMESPACE]: GREEK },
    [LANGUAGE_CODES.ENGLISH]: { [I18N_NAMESPACE]: ENGLISH },
    [LANGUAGE_CODES.SPANISH]: { [I18N_NAMESPACE]: SPANISH },
    [LANGUAGE_CODES.FINNISH]: { [I18N_NAMESPACE]: FINNISH },
    [LANGUAGE_CODES.FRENCH]: { [I18N_NAMESPACE]: FRENCH },
    [LANGUAGE_CODES.ITALIAN]: { [I18N_NAMESPACE]: ITALIAN },
    [LANGUAGE_CODES.JAPANESE]: { [I18N_NAMESPACE]: JAPANESE },
    [LANGUAGE_CODES.KOREAN]: { [I18N_NAMESPACE]: KOREAN },
    [LANGUAGE_CODES.NORWEGIAN_BOKML]: { [I18N_NAMESPACE]: NORWEGIAN },
    [LANGUAGE_CODES.NORWEGIAN_NYNORSK]: { [I18N_NAMESPACE]: NORWEGIAN },
    [LANGUAGE_CODES.DUTCH]: { [I18N_NAMESPACE]: DUTCH },
    [LANGUAGE_CODES.POLISH]: { [I18N_NAMESPACE]: POLISH },
    [LANGUAGE_CODES.PORTUGUESE]: { [I18N_NAMESPACE]: PORTUGUESE },
    [LANGUAGE_CODES.RUSSIAN]: { [I18N_NAMESPACE]: RUSSIAN },
    [LANGUAGE_CODES.SWEDISH]: { [I18N_NAMESPACE]: SWEDISH },
    [LANGUAGE_CODES.TURKISH]: { [I18N_NAMESPACE]: TURKISH },
    [LANGUAGE_CODES.CHINESE]: { [I18N_NAMESPACE]: CHINESE_SIMPLIFIED },
    [LANGUAGE_CODES.CHINESE_SIMPLIFIED]: { [I18N_NAMESPACE]: CHINESE_SIMPLIFIED },
    [LANGUAGE_CODES.CHINESE_TRADITIONAL]: {
        [I18N_NAMESPACE]: CHINESE_TRADITIONAL,
    },
};

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: RESOURCES,
    fallbackLng: 'en',
    debug: false,
    keySeparator: '.', // we use content as keys
    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },
    react: {
        wait: true,
    },
});

export default i18n;
