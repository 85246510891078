import React from 'react';
import { Trans } from 'react-i18next';

const Landing = (props) => {
    return (
        <div className="landing-screen">
            <main>
                <div className="column-left">
                    {/* desktop only -- contains an image */}
                </div>
                <div className="column-right">
                    <p className="product-name">
                        <Trans i18nKey="intro.installationGuideProductName" />
                    </p>
                    <p className="label">
                        <Trans i18nKey="intro.installationGuide" />
                    </p>
                    <div className="button-container">
                        <button
                            className="btn btn-primary-landing"
                            onClick={props.continue}
                        >
                            <Trans i18nKey="intro.beginInstall" />
                        </button>
                        <button
                            className="btn btn-advanced"
                            onClick={props.hirePro}
                        >
                            <Trans i18nKey="menu.hirePro" />
                        </button>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Landing;
