import React from 'react';
import { Trans } from 'react-i18next';

//  Other
import ConfigurationManager from '../services/configuration';
import {goToLink} from '../utilities/statics';
import {CHOICE_TYPES, LINKS} from '../data/constants';


const ButtonFooter = (props) => {

    const {terminalStatus, hasDoorbell} = ConfigurationManager.getHardwareConfiguration();
    const hireLink = hasDoorbell ? LINKS.HIRE_PRO_HAS_DOORBELL : LINKS.HIRE_PRO_NO_DOORBELL;

    if (terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_CONTACT_SUPPORT) {
        return (
            <div className="button-footer">
                <button
                    className="btn btn-tertiary"
                    onClick={() => {props.startOver(false)}}
                >
                    <Trans i18nKey="intro.startOver" />
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => goToLink(LINKS.SUBMIT_TO_SUPPORT)}
                >
                    <Trans i18nKey="intro.contactSupport" />
                </button>
            </div>
        );
    } else if (terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_FAIL) {
        return (
            <div className="button-footer">
                <button
                    className="btn btn-tertiary"
                    onClick={() => {props.startOver(false)}}
                >
                    <Trans i18nKey="intro.startOver" />
                </button>
            </div>
        );
    } else if (terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_HIRE_PRO) {
        return (
            <div className="button-footer">
                <button
                    className="btn btn-tertiary"
                    onClick={() => {props.startOver(false)}}
                >
                    <Trans i18nKey="intro.startOver" />
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={props.selectNextSection}
                >
                    <Trans i18nKey="intro.continueInstall" />
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => goToLink(hireLink)}
                >
                    <Trans i18nKey="menu.hirePro" />
                </button>
            </div>
        );
    } else if (terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_SUCCESS_OWNS_DOORBELL) {
        return (
            <div className="button-footer">
                <button
                    className="btn btn-tertiary"
                    onClick={() => {props.startOver(false)}}
                >
                    <Trans i18nKey="intro.startOver" />
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => goToLink(hireLink)}
                >
                    <Trans i18nKey="menu.hirePro" />
                </button>
                <button
                    className="btn btn-primary"
                    onClick={props.selectNextSection}
                >
                    <Trans i18nKey="intro.continueInstall" />
                </button>
            </div>
        );
    } else if (terminalStatus === CHOICE_TYPES.TYPE_BUTTON_CONFIG_SUCCESS_BUY_DOORBELL) {
        return (
            <div className="button-footer">
                <button
                    className="btn btn-tertiary"
                    onClick={() => {props.startOver(false)}}
                >
                    <Trans i18nKey="intro.startOver" />
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => goToLink(LINKS.BUY_PRODUCT)}
                >
                    <Trans i18nKey="intro.buyProduct" />
                </button>
            </div>
        );
    }

    return (
            <div className="button-footer">
            </div>
        );

}

export default ButtonFooter;
